import { Icon, IconProps } from "@chakra-ui/react";
const LocationIcon = (props: IconProps) => <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="LocationIcon" data-sentry-source-file="Location.tsx">
    <g clipPath="url(#clip0_4065_149143)" data-sentry-element="g" data-sentry-source-file="Location.tsx">
      <path d="M12 1.5C7.85357 1.5 4.5 4.7865 4.5 8.85C4.5 13.3109 9.41154 19.4909 11.2854 21.6864C11.6648 22.1309 12.3352 22.1309 12.7146 21.6864C14.5885 19.4909 19.5 13.3109 19.5 8.85C19.5 4.7865 16.1464 1.5 12 1.5ZM12 11.475C10.5214 11.475 9.32143 10.299 9.32143 8.85C9.32143 7.401 10.5214 6.225 12 6.225C13.4786 6.225 14.6786 7.401 14.6786 8.85C14.6786 10.299 13.4786 11.475 12 11.475Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Location.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Location.tsx">
      <clipPath id="clip0_4065_149143" data-sentry-element="clipPath" data-sentry-source-file="Location.tsx">
        <rect width={24} height={24} fill="white" data-sentry-element="rect" data-sentry-source-file="Location.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default LocationIcon;