import { radioAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    borderColor: "blue.500", // change the border color
  },
})

// define custom variant
const variants = {
  snowday: definePartsStyle({
    control: {
      borderColor: "#62ABEA",
      background: "transparent",

      _checked: {
        background: "transparent",
        borderColor: "#62ABEA",
        color: "#62ABEA",

        _hover: {
          background: "transparent",
          borderColor: "#62ABEA",
          color: "#62ABEA",
        },
      },
      _hover: {
        background: "#62ABEA",
        borderColor: "#62ABEA",
      },
    },
  }),
  filter: definePartsStyle({
    container: {
      display: "flex",
      justifyContent: "center",
      px: 4,
      py: 1,
      borderTop: "1px solid rgba(33, 23, 40, 0.12)",
      borderBottom: "1px solid rgba(33, 23, 40, 0.12)",
      margin: 0,
      marginInline: "0 !important",
      borderRight: "1px solid rgba(33, 23, 40, 0.12)",
      _first: {
        borderLeft: "1px solid rgba(33, 23, 40, 0.12)",
        borderLeftRadius: 16,
        pl: 6,
      },
      _last: {
        borderRight: "1px solid rgba(33, 23, 40, 0.12)",
        borderRightRadius: 16,
        pr: 6,
      },
      _checked: {
        bg: "snow.blue-light",
      },
      _hover: {
        bg: "snow.blue-light",
      },
    },
    control: {
      display: "none",
    },
    label: {
      margin: 0,
      color: "snow.blue-dark",
      fontSize: 14,
      _checked: {
        fontWeight: 700,
      },
    },
  }),
}

// export the component theme
export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
})
