import { Icon, IconProps } from "@chakra-ui/react";
const ResearchIcon = (props: IconProps) => <Icon width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="ResearchIcon" data-sentry-source-file="Research.tsx">
    <g clipPath="url(#clip0_4068_149481)" data-sentry-element="g" data-sentry-source-file="Research.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.86688 0.742726C10.1176 0.131402 10.8164 -0.160927 11.4277 0.089787L29.2544 7.40173C29.8657 7.65247 30.158 8.35129 29.9073 8.96259L28.9614 11.2686C28.8509 11.538 28.5429 11.6669 28.2734 11.5564C28.004 11.4458 27.8751 11.1378 27.9856 10.8683L28.9315 8.56239C28.9315 8.56238 28.9315 8.5624 28.9315 8.56239C28.9612 8.48997 28.9265 8.40719 28.8542 8.37752L11.0275 1.0656C11.0275 1.0656 11.0276 1.0656 11.0275 1.0656C10.9552 1.03592 10.8724 1.07054 10.8427 1.14292L1.0656 24.9795C1.03589 25.0519 1.07055 25.1347 1.14289 25.1644L18.9695 32.4763C18.9695 32.4763 18.9695 32.4763 18.9695 32.4763C19.0419 32.506 19.1247 32.4714 19.1544 32.399L27.2119 12.7548C27.3224 12.4854 27.6304 12.3565 27.8999 12.467C28.1693 12.5776 28.2982 12.8856 28.1877 13.1551L20.1302 32.7992C20.1302 32.7992 20.1302 32.7992 20.1302 32.7992C19.8795 33.4105 19.1806 33.7028 18.5693 33.4521L0.742633 26.1402C0.131391 25.8894 -0.160919 25.1906 0.0897854 24.5793L9.86688 0.742726Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path d="M24.6323 30.3219H5.36434C4.99484 30.3219 4.69531 30.0224 4.69531 29.6529V3.88899C4.69531 3.5195 4.99484 3.21997 5.36434 3.21997H24.6323C25.0018 3.21997 25.3013 3.5195 25.3013 3.88899V29.6529C25.3013 30.0224 25.0018 30.3219 24.6323 30.3219Z" fill="white" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.16797 3.88899C4.16797 3.22826 4.7036 2.69263 5.36434 2.69263H24.6323C25.293 2.69263 25.8286 3.22826 25.8286 3.88899V29.6529C25.8286 30.3136 25.293 30.8493 24.6323 30.8493H5.36434C4.7036 30.8493 4.16797 30.3136 4.16797 29.6529V8.28395C4.16797 7.9927 4.40407 7.7566 4.69531 7.7566C4.98656 7.7566 5.22266 7.9927 5.22266 8.28395V29.6529C5.22266 29.7311 5.28609 29.7946 5.36434 29.7946H24.6323C24.7105 29.7946 24.774 29.7311 24.774 29.6529V3.88899C24.774 3.81075 24.7105 3.74731 24.6323 3.74731H5.36434C5.28609 3.74731 5.22266 3.81075 5.22266 3.88899V6.24488C5.22266 6.53613 4.98656 6.77223 4.69531 6.77223C4.40407 6.77223 4.16797 6.53613 4.16797 6.24488V3.88899Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.61621 6.77869V12.098H12.2482V6.77869H8.61621ZM7.56152 6.58645C7.56152 6.11015 7.94767 5.724 8.42398 5.724H12.4404C12.9167 5.724 13.3029 6.11015 13.3029 6.58645V12.2903C13.3029 12.7667 12.9167 13.1527 12.4404 13.1527H8.42398C7.94767 13.1527 7.56152 12.7666 7.56152 12.2903V6.58645Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path d="M12.4404 12.6254H8.42398C8.23891 12.6254 8.08887 12.4753 8.08887 12.2903V6.58645C8.08887 6.40139 8.23891 6.25134 8.42398 6.25134H12.4404C12.6255 6.25134 12.7755 6.40139 12.7755 6.58645V12.2903C12.7755 12.4754 12.6256 12.6254 12.4404 12.6254Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56104 15.8301C7.56104 15.5388 7.79713 15.3027 8.08838 15.3027H17.0117C17.303 15.3027 17.5391 15.5388 17.5391 15.8301C17.5391 16.1213 17.303 16.3574 17.0117 16.3574H8.08838C7.79713 16.3574 7.56104 16.1213 7.56104 15.8301Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56104 18.0001C7.56104 17.7088 7.79713 17.4727 8.08838 17.4727H15.6758C15.967 17.4727 16.2031 17.7088 16.2031 18.0001C16.2031 18.2913 15.967 18.5274 15.6758 18.5274H8.08838C7.79713 18.5274 7.56104 18.2913 7.56104 18.0001Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56104 20.17C7.56104 19.8788 7.79713 19.6427 8.08838 19.6427H14.6914C14.9827 19.6427 15.2188 19.8788 15.2188 20.17C15.2188 20.4613 14.9827 20.6974 14.6914 20.6974H8.08838C7.79713 20.6974 7.56104 20.4613 7.56104 20.17Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56104 22.34C7.56104 22.0488 7.79713 21.8127 8.08838 21.8127H13.9883C14.2795 21.8127 14.5156 22.0488 14.5156 22.34C14.5156 22.6313 14.2795 22.8674 13.9883 22.8674H8.08838C7.79713 22.8674 7.56104 22.6313 7.56104 22.34Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56104 24.51C7.56104 24.2188 7.79713 23.9827 8.08838 23.9827H14.6914C14.9827 23.9827 15.2188 24.2188 15.2188 24.51C15.2188 24.8013 14.9827 25.0374 14.6914 25.0374H8.08838C7.79713 25.0374 7.56104 24.8013 7.56104 24.51Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56104 26.68C7.56104 26.3887 7.79713 26.1526 8.08838 26.1526H15.6758C15.967 26.1526 16.2031 26.3887 16.2031 26.68C16.2031 26.9712 15.967 27.2073 15.6758 27.2073H8.08838C7.79713 27.2073 7.56104 26.9712 7.56104 26.68Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0024 7.12939C15.0024 6.83815 15.2385 6.60205 15.5298 6.60205H21.9083C22.1995 6.60205 22.4356 6.83815 22.4356 7.12939C22.4356 7.42064 22.1995 7.65674 21.9083 7.65674H15.5298C15.2385 7.65674 15.0024 7.42064 15.0024 7.12939Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0024 9.23492C15.0024 8.94368 15.2385 8.70758 15.5298 8.70758H21.9083C22.1995 8.70758 22.4356 8.94368 22.4356 9.23492C22.4356 9.52617 22.1995 9.76227 21.9083 9.76227H15.5298C15.2385 9.76227 15.0024 9.52617 15.0024 9.23492Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0024 11.3404C15.0024 11.0491 15.2385 10.813 15.5298 10.813H21.9083C22.1995 10.813 22.4356 11.0491 22.4356 11.3404C22.4356 11.6316 22.1995 11.8677 21.9083 11.8677H15.5298C15.2385 11.8677 15.0024 11.6316 15.0024 11.3404Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.6097 24.7206C26.8157 24.5147 27.1496 24.5147 27.3555 24.7206L29.8775 27.2426C30.0834 27.4485 30.0834 27.7824 29.8775 27.9884L27.9881 29.8777C27.7822 30.0836 27.4483 30.0836 27.2423 29.8777L24.7204 27.3557C24.6215 27.2568 24.5659 27.1227 24.5659 26.9828C24.5659 26.8429 24.6215 26.7088 24.7204 26.6099L26.6097 24.7206ZM26.9826 25.8393L25.839 26.9828L27.6152 28.759L28.7588 27.6155L26.9826 25.8393Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path d="M29.8644 27.2557L27.2557 29.8644C27.1804 29.9397 27.1804 30.0618 27.2557 30.1371L31.9945 34.876C32.7902 35.6716 34.0803 35.6716 34.876 34.876C35.6716 34.0803 35.6716 32.7902 34.876 31.9945L30.1371 27.2557C30.0618 27.1804 29.9397 27.1804 29.8644 27.2557Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path d="M22.5151 28.6602C25.9088 28.6602 28.66 25.909 28.66 22.5153C28.66 19.1215 25.9088 16.3703 22.5151 16.3703C19.1213 16.3703 16.3701 19.1215 16.3701 22.5153C16.3701 25.909 19.1213 28.6602 22.5151 28.6602Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5151 16.8976C19.4126 16.8976 16.8975 19.4127 16.8975 22.5153C16.8975 25.6178 19.4126 28.1329 22.5151 28.1329C25.6176 28.1329 28.1327 25.6178 28.1327 22.5153C28.1327 19.4127 25.6176 16.8976 22.5151 16.8976ZM15.8428 22.5153C15.8428 18.8302 18.8301 15.843 22.5151 15.843C26.2001 15.843 29.1874 18.8302 29.1874 22.5153C29.1874 26.2003 26.2001 29.1876 22.5151 29.1876C18.8301 29.1876 15.8428 26.2003 15.8428 22.5153Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path d="M22.5154 26.5599C24.7492 26.5599 26.56 24.749 26.56 22.5152C26.56 20.2814 24.7492 18.4706 22.5154 18.4706C20.2816 18.4706 18.4707 20.2814 18.4707 22.5152C18.4707 24.749 20.2816 26.5599 22.5154 26.5599Z" fill="white" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5154 18.9979C20.5728 18.9979 18.998 20.5727 18.998 22.5152C18.998 24.4578 20.5728 26.0325 22.5154 26.0325C24.4579 26.0325 26.0327 24.4578 26.0327 22.5152C26.0327 20.5727 24.4579 18.9979 22.5154 18.9979ZM17.9434 22.5152C17.9434 19.9902 19.9903 17.9432 22.5154 17.9432C25.0404 17.9432 27.0874 19.9902 27.0874 22.5152C27.0874 25.0403 25.0404 27.0872 22.5154 27.0872C19.9903 27.0872 17.9434 25.0403 17.9434 22.5152Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.6412 32.6416C32.8471 32.4357 33.181 32.4356 33.387 32.6415L33.6987 32.9532C33.9047 33.1591 33.9047 33.493 33.6988 33.6989C33.4929 33.9049 33.159 33.9049 32.953 33.699L32.6413 33.3874C32.4354 33.1815 32.4353 32.8476 32.6412 32.6416Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.638 28.1638C32.844 28.3697 32.844 28.7036 32.638 28.9096L28.909 32.6386C28.7031 32.8445 28.3692 32.8445 28.1632 32.6386C27.9573 32.4327 27.9573 32.0988 28.1632 31.8928L31.8923 28.1638C32.0982 27.9579 32.4321 27.9579 32.638 28.1638Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.4915 26.8828C29.7728 26.6016 30.2287 26.6016 30.51 26.8828L35.2488 31.6217C36.2504 32.6233 36.2504 34.2472 35.2488 35.2488C34.4927 36.005 33.3831 36.1892 32.4548 35.8055C32.1856 35.6943 32.0576 35.3859 32.1688 35.1167C32.2801 34.8476 32.5885 34.7195 32.8576 34.8308C33.406 35.0574 34.059 34.9472 34.5031 34.5031C35.0928 33.9134 35.0928 32.9572 34.5031 32.3674L30.0008 27.8651L27.8651 30.0008L31.4296 33.5652C31.6356 33.7712 31.6356 34.1051 31.4296 34.311C31.2237 34.517 30.8898 34.517 30.6838 34.311L26.8828 30.51C26.6016 30.2287 26.6016 29.7728 26.8828 29.4915L29.4915 26.8828Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Research.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Research.tsx">
      <clipPath id="clip0_4068_149481" data-sentry-element="clipPath" data-sentry-source-file="Research.tsx">
        <rect width={36} height={36} fill="white" data-sentry-element="rect" data-sentry-source-file="Research.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default ResearchIcon;