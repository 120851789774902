import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";
const CheckmarkIcon = (props: IconProps) => <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="CheckmarkIcon" data-sentry-source-file="Checkmark.tsx">
    <g clipPath="url(#clip0_8571_2987)" data-sentry-element="g" data-sentry-source-file="Checkmark.tsx">
      <path d="M20 6L9 17L4 12" stroke="#257AC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Checkmark.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Checkmark.tsx">
      <clipPath id="clip0_8571_2987" data-sentry-element="clipPath" data-sentry-source-file="Checkmark.tsx">
        <rect width="24" height="24" fill="white" data-sentry-element="rect" data-sentry-source-file="Checkmark.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default CheckmarkIcon;