import { Box, Text } from "@chakra-ui/react";
import Link from "next/link";
import { Logo } from "../common/Logo";
import EmailIcon from "@/icons/Email";
type ContactUsType = {
  showLogo?: boolean;
};
export const ContactUsComponent = ({
  showLogo
}: ContactUsType) => {
  return <Box data-sentry-element="Box" data-sentry-component="ContactUsComponent" data-sentry-source-file="ContactUsComponent.tsx">
      {showLogo && <Box mb={4}>
          <Logo color="snow.blue" showName={true} />
        </Box>}
      <Text fontWeight={700} fontSize="inherit" mb={1} lineHeight={{
      base: "16px"
    }} data-sentry-element="Text" data-sentry-source-file="ContactUsComponent.tsx">
        Question or feedback?
      </Text>
      <Link style={{
      display: "inline-flex",
      alignItems: "center"
    }} href="mailto:ask@snow.day" data-sentry-element="Link" data-sentry-source-file="ContactUsComponent.tsx">
        Contact Us <EmailIcon height="16px" data-sentry-element="EmailIcon" data-sentry-source-file="ContactUsComponent.tsx" />
      </Link>
    </Box>;
};