import { drawerAnatomy as parts } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const helpers = createMultiStyleConfigHelpers(parts.keys)

export const Drawer = helpers.defineMultiStyleConfig({
  baseStyle: {
    overlay: {
      bg: "snow.blue-overlay",
    },
  },
  variants: {
    keepHeading: {
      overlay: {
        height: {
          base: "calc(100svh - 48px) !important",
          md: "calc(100svh - 64px) !important",
        },
        top: {
          base: "48px",
          md: "64px",
        },
      },
      dialogContainer: {
        height: {
          base: "calc(100svh - 48px) !important",
          md: "calc(100svh - 64px) !important",
        },
        top: {
          base: "48px",
          md: "64px",
        },
      },
      dialog: {
        height: {
          base: "calc(100svh - 48px) !important",
          md: "calc(100svh - 64px) !important",
        },
        top: {
          base: "48px !important",
          md: "64px !important",
        },
      },
    },
  },
})
