import { defineStyleConfig } from "@chakra-ui/react"

export const Tag = defineStyleConfig({
  // The styles all tags have in common
  baseStyle: {
    color: "snow.blue-dark",
    container: {
      rounded: "full", // <-- border radius is same for all variants and sizes
      bg: "snow.blue-light",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "primary",
    colorScheme: "snow.blue",
  },
})
