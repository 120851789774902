import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import { unwrap } from "@/utils/array"

export const referralKey = "utm_content"
export const utmSourceKey = "email"
export const utmMediumKey = "hubspot"
export const utmCampaignKey = "invite_link"

export function useReferral() {
  const { query } = useRouter()

  const [referral, setReferral] = useState<string | null>(null)

  useEffect(() => {
    if (!window) {
      return
    }

    const storageValue = localStorage.getItem(referralKey)
    const value = unwrap(query[referralKey] || storageValue)

    if (!value) {
      return
    }

    if (storageValue !== value) {
      localStorage.setItem(referralKey, value)
    }

    setReferral(value)
  }, [])

  return referral
}
