import { FinancialAccessibility, Restriction } from "@/generated/graphql"

export const financialAccessibilityOptions = [
  {
    label: "A+",
    value: FinancialAccessibility.APlus,
    description: "Free +",
  },
  {
    label: "A",
    value: FinancialAccessibility.A,
    description: "Free",
  },
  {
    label: "A-",
    value: FinancialAccessibility.AMinus,
    description: "Very low cost with good aid",
  },
  {
    label: "B+",
    value: FinancialAccessibility.BPlus,
    description: "Low cost with aid",
  },
  {
    label: "B-",
    value: FinancialAccessibility.BMinus,
    description: "Average cost with aid",
  },
  {
    label: "C+",
    value: FinancialAccessibility.CPlus,
    description: "High cost with limited aid",
  },
  {
    label: "N/A",
    value: FinancialAccessibility.None,
    description: "Not Available",
  },
]

export const radiusValues = [10, 25, 50, 100]

export const noRestrictionOption = {
  label: "No Restrictions",
  value: true,
}
export const eligibilityOptions: { label: string; value: Restriction }[] = [
  {
    label: "Low-Income Only",
    value: Restriction.LowIncome,
  },
  {
    label: "1st Generation Students Only",
    value: Restriction.FirstGen,
  },
  {
    label: "Under-represented Minorities Only",
    value: Restriction.UnderRepresentedMinority,
  },
  {
    label: "US Citizens Only",
    value: Restriction.UsCitizen,
  },
  {
    label: "US Residents Only",
    value: Restriction.UsResident,
  },
]
