import { usePushQueryUpdates } from "./usePushQueryUpdates"

export const usePushQueryUpdate = () => {
  const pushQueryUpdates = usePushQueryUpdates()

  return (key: string, value: string | null | undefined) => {
    const updates: Record<string, string | null | undefined> = {}
    updates[key] = value

    return pushQueryUpdates(updates)
  }
}
