import { modalAnatomy as parts } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
const helpers = createMultiStyleConfigHelpers(parts.keys)

export const Modal = helpers.defineMultiStyleConfig({
  baseStyle: {
    overlay: {
      bg: "snow.blue-overlay",
    },
    dialog: {
      mx: 5,
    },
  },
  defaultProps: {
    // @ts-expect-error: `isCentered` is not a valid defaultProp for `Modal`,
    // but it is used as prop in the component and actually works. Somehow
    // the type definition is wrong or not yet implemented by chakra.
    isCentered: true,
  },
})
