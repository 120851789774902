import { Button, FormControl, FormLabel, Heading, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Select, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { UserType, useUpdateProfileMutation } from "../../../generated/graphql";
import graphqlRequestClient from "../../graphql/client";
import { useCurrentUser } from "@/auth/hooks/useCurrentUser";
export const UserInfoModal = () => {
  const {
    currentUser,
    refetchCurrentUser
  } = useCurrentUser();
  const updateProfileMutation = useUpdateProfileMutation(graphqlRequestClient);
  const isOpen = currentUser !== undefined && (!currentUser.name || !currentUser.type);
  const [name, setName] = useState(currentUser?.name);
  const [type, setType] = useState(currentUser?.type ?? UserType.Student);
  const [loading, setLoading] = useState(false);
  const canSubmit = name && type;

  /**
   * We don't want users to be able to close this one manually!! It only closes when the user
   * completes their profile.
   */
  const handleClose = () => {
    return;
  };
  const handleTypeChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType((event.target.value as UserType));
  };
  const handleNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleSubmit = async () => {
    if (!name || !type || !currentUser?.identifier) {
      return;
    }
    setLoading(true);
    await updateProfileMutation.mutateAsync({
      input: {
        identifier: currentUser!.identifier,
        name,
        type
      }
    });
    await refetchCurrentUser?.();
    setLoading(false);
  };
  return <Modal isOpen={isOpen} onClose={handleClose} data-sentry-element="Modal" data-sentry-component="UserInfoModal" data-sentry-source-file="UserProfileModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="UserProfileModal.tsx" />
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="UserProfileModal.tsx">
        <ModalHeader pb={0} data-sentry-element="ModalHeader" data-sentry-source-file="UserProfileModal.tsx">
          <Heading data-sentry-element="Heading" data-sentry-source-file="UserProfileModal.tsx">User Profile</Heading>
        </ModalHeader>
        <ModalBody data-sentry-element="ModalBody" data-sentry-source-file="UserProfileModal.tsx">
          <Text mb={4} data-sentry-element="Text" data-sentry-source-file="UserProfileModal.tsx">
            Set up your account to start saving learning opportunities to your
            profile and share them with family members or advisors.
          </Text>
          <FormControl mb={4} data-sentry-element="FormControl" data-sentry-source-file="UserProfileModal.tsx">
            <FormLabel fontWeight="bold" data-sentry-element="FormLabel" data-sentry-source-file="UserProfileModal.tsx">I am a...</FormLabel>
            <Select value={type} onChange={handleTypeChanged} data-sentry-element="Select" data-sentry-source-file="UserProfileModal.tsx">
              <option value={UserType.Student}>Student</option>
              <option value={UserType.Parent}>Parent/Guardian</option>
              <option value={UserType.SchoolCounselor}>School Counselor</option>
              <option value={UserType.IndependentCounselor}>
                Independent Counselor
              </option>
              <option value={UserType.Other}>Other</option>
            </Select>
          </FormControl>
          <FormControl mb={4} data-sentry-element="FormControl" data-sentry-source-file="UserProfileModal.tsx">
            <FormLabel fontWeight="bold" data-sentry-element="FormLabel" data-sentry-source-file="UserProfileModal.tsx">Name</FormLabel>
            <Input value={name ?? ""} onChange={handleNameChanged} data-sentry-element="Input" data-sentry-source-file="UserProfileModal.tsx" />
          </FormControl>
          <Button isDisabled={!canSubmit} isLoading={loading} onClick={handleSubmit} mb={4} data-sentry-element="Button" data-sentry-source-file="UserProfileModal.tsx">
            Save
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>;
};