import { identity } from "./fp"
import { Maybe } from "@/generated/graphql"

export type MaybeArray<T> = T | T[]

export const wrap = <T>(value: MaybeArray<T>): T[] =>
  Array.isArray(value) ? value : [value]

export const unwrap = <T>(value: MaybeArray<T>): T | undefined =>
  Array.isArray(value) ? value[0] : value

export const stripMaybe = <T>(value: Maybe<T> | T): value is T => value !== null

export const createUniqueInArrayFilter =
  <T>(fn: (value: T) => string | number) =>
  (item: T, index: number, items: T[]) =>
    items.findIndex((current) => fn(current) === fn(item)) === index

export const unique = <T>(values: T[], by: (v: T) => T | string = identity) =>
  values.filter(
    (value, index, values) =>
      index === values.findIndex((target) => by(target) === by(value)),
  )

export const partition = <T, V extends string | number>(
  values: T[],
  by: (v: T) => V,
) =>
  values.reduce(
    (acc: Record<V, T[]>, value: T) => {
      const key = by(value)
      acc[key] = (acc[key] ?? []).concat([value])
      return acc
    },
    {} as Record<V, T[]>,
  )
