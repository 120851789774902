import { HStack, Link } from "@chakra-ui/react";
import InstagramIcon from "../icons/Instagram";
import LinkedInIcon from "../icons/LinkedIn";
export const SocialLinks = () => {
  return <HStack width="100%" justifyContent="flex-end" data-sentry-element="HStack" data-sentry-component="SocialLinks" data-sentry-source-file="SocialLinks.tsx">
      <Link href="https://www.instagram.com/snowday_edu/" target="_blank" data-sentry-element="Link" data-sentry-source-file="SocialLinks.tsx">
        <InstagramIcon boxSize={4} data-sentry-element="InstagramIcon" data-sentry-source-file="SocialLinks.tsx" />
      </Link>
      <Link href="https://www.linkedin.com/company/85882777/" target="_blank" data-sentry-element="Link" data-sentry-source-file="SocialLinks.tsx">
        <LinkedInIcon boxSize={4} data-sentry-element="LinkedInIcon" data-sentry-source-file="SocialLinks.tsx" />
      </Link>
    </HStack>;
};