import { Icon, IconProps } from "@chakra-ui/react";
const SparkleIcon = (props: IconProps) => <Icon width="24px" height="24px" viewBox="0 0 512 512" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="SparkleIcon" data-sentry-source-file="Sparkle.tsx">
    <g data-sentry-element="g" data-sentry-source-file="Sparkle.tsx">
      <path d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917
        c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9
        c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904
        C315.08,148.422,272.014,131.551,247.355,106.9z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Sparkle.tsx" />
      <path d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872
        c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864
        c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82
        C447.939,329.14,422.201,319.061,407.471,304.339z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Sparkle.tsx" />
      <path d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027
        c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626
        c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019
        C159.024,395.966,150.555,374.351,146.352,354.702z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Sparkle.tsx" />
    </g>
  </Icon>;
export default SparkleIcon;