import { Icon, IconProps } from "@chakra-ui/react";
const SearchIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="SearchIcon" data-sentry-source-file="Search.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="Search.tsx">
      <path fill="currentColor" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l4.293 4.284a1 1 0 0 0 1.413 0l.078-.078a1 1 0 0 0 0-1.413L15.5 14Zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14Z" data-sentry-element="path" data-sentry-source-file="Search.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Search.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="Search.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Search.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default SearchIcon;