import { usePathname } from "next/navigation"
import { useRouter } from "next/router"

import { useUpdateQueryString } from "./useUpdateQueryString"

export const usePushQueryUpdates = () => {
  const router = useRouter()
  const pathname = usePathname()

  const updateQueryString = useUpdateQueryString()

  return (updates: Record<string, string | null | undefined>) => {
    const queryString = updateQueryString(updates)

    if (queryString.length > 0) {
      router.push(`${pathname}?${queryString}`, undefined, { scroll: false })
    } else {
      router.push(pathname, undefined, { scroll: false })
    }
  }
}
