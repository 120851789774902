import { Icon, IconProps } from "@chakra-ui/react";
const EmailIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="EmailIcon" data-sentry-source-file="Email.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="Email.tsx">
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm0 4-8 5-8-5V6l8 5 8-5v2Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Email.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Email.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="Email.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Email.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default EmailIcon;