import { QueryClient } from "@tanstack/react-query"
import { GraphQLClient } from "graphql-request"

const GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || "http://localhost:8080/graphql"

const graphqlRequestClient = new GraphQLClient(GRAPHQL_ENDPOINT)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5, // 5 retries
      retryDelay: 1000, // 1 second
    },
  },
})

export default graphqlRequestClient
