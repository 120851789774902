import { Icon, IconProps } from "@chakra-ui/react";
const HomeIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="HomeIcon" data-sentry-source-file="Home.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="Home.tsx">
      <path d="M9 20a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-6.168c0-.46.372-.832.832-.832.762 0 1.123-.94.556-1.45L12.67 3.602a1 1 0 0 0-1.338 0l-7.72 6.948c-.566.51-.205 1.45.557 1.45.46 0 .832.373.832.832V19a1 1 0 0 0 1 1h3Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Home.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Home.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="Home.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Home.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default HomeIcon;