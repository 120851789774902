import { Icon, IconProps } from "@chakra-ui/react";
const OneOnOneIcon = (props: IconProps) => <Icon width={315} height={315} viewBox="0 0 315 332" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="OneOnOneIcon" data-sentry-source-file="OneOnOne.tsx">
    <circle cx="59.5742" cy="140.095" r="28.5707" fill="#62ABEA" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="59.5742" cy="140.095" r="28.5707" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="59.5742" cy="140.095" r="28.5707" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="59.5742" cy="140.095" r="28.5707" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="59.5742" cy="140.095" r="28.5707" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <path d="M77.3002 210.094L102.415 222.502L142.3 201.207L129.422 180.356L104.949 193.218L101.99 194.773L99.3302 192.749L71.5567 171.614L39.7342 169.557L21.1302 235.366C16.6991 251.04 27.7977 266.825 44.0469 267.959L75.3248 270.142L80.2069 270.483L80.2069 275.377L80.2069 319.056L111.601 319.056L111.601 245.555C111.601 243.271 109.859 241.364 107.584 241.158L74.5756 238.174C74.5753 238.174 74.5751 238.174 74.5748 238.174C71.8207 237.925 69.712 235.616 69.712 232.852L77.3002 210.094ZM77.3002 210.094C73.8028 208.364 69.712 210.912 69.712 214.806C69.712 214.807 69.712 214.807 69.712 214.807L69.712 232.851L77.3002 210.094ZM72.6491 219.502L72.6538 219.505C72.6514 219.503 72.649 219.502 72.6466 219.501L72.6491 219.502Z" fill="#62ABEA" stroke="#344372" strokeWidth="10.4949" data-sentry-element="path" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="33.8182" cy="33.8182" r="28.5707" transform="matrix(-1 0 0 1 294.621 106.286)" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="33.8182" cy="33.8182" r="28.5707" transform="matrix(-1 0 0 1 294.621 106.286)" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="33.8182" cy="33.8182" r="28.5707" transform="matrix(-1 0 0 1 294.621 106.286)" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <circle cx="33.8182" cy="33.8182" r="28.5707" transform="matrix(-1 0 0 1 294.621 106.286)" stroke="#344372" strokeWidth="10.4949" data-sentry-element="circle" data-sentry-source-file="OneOnOne.tsx" />
    <path d="M246.726 213.995L246.726 236.63L246.726 236.631C246.726 239.583 244.333 241.974 241.382 241.975L241.382 241.975L207.66 241.975C205.222 241.975 203.245 243.951 203.245 246.39L203.245 319.056L234.64 319.056L234.64 275.377L234.64 270.483L239.522 270.142L271.371 267.919C287.465 266.796 298.546 251.282 294.389 235.694L276.737 169.507L243.723 169.507L217.591 196.871L216.041 198.494L213.796 198.494L185.962 198.494L185.962 221.817L226.095 221.817L237.77 210.259L237.77 210.259C241.089 206.973 246.726 209.324 246.726 213.995C246.726 213.995 246.726 213.995 246.726 213.995Z" stroke="#344372" strokeWidth="10.4949" data-sentry-element="path" data-sentry-source-file="OneOnOne.tsx" />
    <mask id="path-5-inside-1_3_69" fill="white" data-sentry-element="mask" data-sentry-source-file="OneOnOne.tsx">
      <rect x="15.7071" y="279.284" width="52.1092" height="44.1958" rx="4.83117" data-sentry-element="rect" data-sentry-source-file="OneOnOne.tsx" />
    </mask>
    <rect x="15.7071" y="279.284" width="52.1092" height="44.1958" rx="4.83117" fill="#344372" stroke="#344372" strokeWidth="19.3247" mask="url(#path-5-inside-1_3_69)" data-sentry-element="rect" data-sentry-source-file="OneOnOne.tsx" />
    <path d="M169.268 253.617H164.437V258.449V318.648L148.621 318.648L148.621 258.449V253.617H143.79H128.395C127.682 253.617 127.104 253.039 127.104 252.326V237.105C127.104 236.393 127.682 235.815 128.395 235.815H183.998C184.711 235.815 185.289 236.393 185.289 237.105V252.326C185.289 253.039 184.711 253.617 183.998 253.617H169.268Z" fill="#344372" stroke="#344372" strokeWidth="9.66233" data-sentry-element="path" data-sentry-source-file="OneOnOne.tsx" />
    <mask id="path-7-inside-2_3_69" fill="white" data-sentry-element="mask" data-sentry-source-file="OneOnOne.tsx">
      <rect x="247.603" y="279.284" width="52.1092" height="44.1958" rx="4.83117" data-sentry-element="rect" data-sentry-source-file="OneOnOne.tsx" />
    </mask>
    <rect x="247.603" y="279.284" width="52.1092" height="44.1958" rx="4.83117" fill="#344372" stroke="#344372" strokeWidth="19.3247" mask="url(#path-7-inside-2_3_69)" data-sentry-element="rect" data-sentry-source-file="OneOnOne.tsx" />
    <path d="M216.494 84.553C222.071 75.2307 224.075 64.201 222.133 53.5127C220.191 42.8244 214.436 33.2043 205.936 26.4394C197.436 19.6745 186.77 16.2249 175.919 16.7312C165.067 17.2375 154.769 21.6655 146.936 29.1925C139.103 36.7194 134.138 46.1261 132.739 61.9362M177.091 103.63C183.248 105.029 189.946 106.101 199.483 100.9" stroke="#344372" strokeWidth="10.4949" data-sentry-element="path" data-sentry-source-file="OneOnOne.tsx" />
    <path d="M217.276 80.2264L222.276 104.286L195.895 101.607" stroke="#344372" strokeWidth="10.4949" data-sentry-element="path" data-sentry-source-file="OneOnOne.tsx" />
    <path d="M112.16 117.217C107.614 109.618 105.981 100.628 107.563 91.9155C109.146 83.2033 113.837 75.3617 120.766 69.8475C127.694 64.3333 136.388 61.5214 145.234 61.9341C154.079 62.3468 162.474 65.9561 168.858 72.0916C175.243 78.227 179.184 86.4713 179.948 95.2931C180.713 104.115 178.249 112.914 173.015 120.057C167.782 127.199 160.133 132.199 151.491 134.127C142.848 136.056 133.8 134.782 126.026 130.542" stroke="#344372" strokeWidth="10.4949" data-sentry-element="path" data-sentry-source-file="OneOnOne.tsx" />
    <path d="M111.44 113.129L107.447 133.302L129.376 131.184" stroke="#344372" strokeWidth="10.4949" data-sentry-element="path" data-sentry-source-file="OneOnOne.tsx" />
  </Icon>;
export default OneOnOneIcon;