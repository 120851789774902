import { ChakraProvider as CP, CSSReset } from "@chakra-ui/react";
import defaultTheme from "./theme/theme";
import { ProviderProps } from "@/providers";
export const ChakraProvider = ({
  children
}: ProviderProps) => {
  return <CP theme={defaultTheme} data-sentry-element="CP" data-sentry-component="ChakraProvider" data-sentry-source-file="ChakraProvider.tsx">
      <CSSReset data-sentry-element="CSSReset" data-sentry-source-file="ChakraProvider.tsx" />
      {children}
    </CP>;
};