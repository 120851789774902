import { Icon, IconProps } from "@chakra-ui/react";
const SchoolIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="SchoolIcon" data-sentry-source-file="School.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="School.tsx">
      <path d="M6.479 13.987A1 1 0 0 0 5 14.865v1.722a1 1 0 0 0 .521.877l6 3.275a1 1 0 0 0 .958 0l6-3.275a1 1 0 0 0 .521-.877v-1.722a1 1 0 0 0-1.479-.878l-5.042 2.752a1 1 0 0 1-.958 0l-5.042-2.752Zm6-10.726a1 1 0 0 0-.958 0L2.61 8.122a1 1 0 0 0 0 1.756l8.912 4.86a1 1 0 0 0 .958 0l7.042-3.841a1 1 0 0 1 1.479.878V16a1 1 0 1 0 2 0V9.594a1 1 0 0 0-.521-.878l-10-5.455Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="School.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="School.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="School.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="School.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default SchoolIcon;