import { Center, Text } from "@chakra-ui/react";
const CalloutBanner = () => {
  return <Center role={"callout-banner"} bg="snow.blue-dark" padding={2} fontSize={{
    base: 12,
    lg: 16
  }} textAlign="center" display="flex" alignItems="center" flexDirection={{
    base: "column",
    lg: "row"
  }} gap={1} data-sentry-element="Center" data-sentry-component="CalloutBanner" data-sentry-source-file="CalloutBanner.tsx">
      <Text color="white" fontSize="inherit" data-sentry-element="Text" data-sentry-source-file="CalloutBanner.tsx">
        New: "Lists" functionality just added!
      </Text>
      <Text color="white" fontSize="inherit" data-sentry-element="Text" data-sentry-source-file="CalloutBanner.tsx">
        Save & share your research.
      </Text>
    </Center>;
};
export default CalloutBanner;