import { Icon, IconProps } from "@chakra-ui/react";
const RightChevronIcon = (props: IconProps) => <Icon width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="RightChevronIcon" data-sentry-source-file="RightChevron.tsx">
    <g clipPath="url(#clip0_8725_1918)" data-sentry-element="g" data-sentry-source-file="RightChevron.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.41205 14.4225C5.08661 14.0971 5.08661 13.5695 5.41205 13.244L10.1561 8.49996L5.41205 3.75588C5.08661 3.43044 5.08661 2.90281 5.41205 2.57737C5.73748 2.25193 6.26512 2.25193 6.59056 2.57737L11.9239 7.9107C12.0802 8.06698 12.168 8.27894 12.168 8.49996C12.168 8.72097 12.0802 8.93293 11.9239 9.08921L6.59056 14.4225C6.26512 14.748 5.73748 14.748 5.41205 14.4225Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="RightChevron.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="RightChevron.tsx">
      <clipPath id="clip0_8725_1918" data-sentry-element="clipPath" data-sentry-source-file="RightChevron.tsx">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" data-sentry-element="rect" data-sentry-source-file="RightChevron.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default RightChevronIcon;