import { Icon, IconProps } from "@chakra-ui/react";
const CopyIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="CopyIcon" data-sentry-source-file="Copy.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="Copy.tsx">
      <path d="M16 2a1 1 0 0 0-1-1H4c-1.1 0-2 .9-2 2v13a1 1 0 1 0 2 0V3h11a1 1 0 0 0 1-1Zm3 3H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Zm0 16H8V7h11v14Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Copy.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Copy.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="Copy.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Copy.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default CopyIcon;