import { radiusValues } from "../options"
import { FiltersType } from "../types"
import { DeadlineValueType } from "@/filters/components/types/DeadlineFilter"
import {
  FinancialAccessibility,
  Grade,
  Interest,
  LearningOpportunityType,
  Restriction,
  Selective,
} from "@/generated/graphql"

enum FilterAction {
  Set = "SET",
  Update = "UPDATE",
  Add = "ADD",
  Remove = "REMOVE",
  Toggle = "TOGGLE",
}

export type LocationType = {
  name: string
  latitude: number
  longitude: number
}

export type AvailabilityType = {
  start?: Date
  end?: Date
}

export type FilterActionValueTypes = {
  [FiltersType.QUERY]?: string
  [FiltersType.COLLEGE_CREDIT]?: boolean
  [FiltersType.INTERESTS]?: Interest
  [FiltersType.SELECTIVE]?: Selective
  [FiltersType.FINANCIAL_ACCESSIBILITY]?: FinancialAccessibility
  [FiltersType.GRADES]?: Grade
  [FiltersType.DEADLINE]?: DeadlineValueType
  [FiltersType.LOCATION]?: LocationType
  [FiltersType.LOCATION_RADIUS]?: number
  [FiltersType.INCLUDE_ONLINE]?: boolean
  [FiltersType.ONLINE_ONLY]?: boolean
  [FiltersType.AVAILABILITY]?: AvailabilityType
  [FiltersType.ELIGIBILITY]?: Restriction
  [FiltersType.NO_RESTRICTIONS]?: boolean
  [FiltersType.TYPE]?: LearningOpportunityType
}

export type FilterState = {
  [FiltersType.QUERY]?: string
  [FiltersType.COLLEGE_CREDIT]?: boolean[] | boolean
  [FiltersType.INTERESTS]?: Interest[] | Interest
  [FiltersType.SELECTIVE]?: Selective[] | Selective
  [FiltersType.FINANCIAL_ACCESSIBILITY]?:
    | FinancialAccessibility[]
    | FinancialAccessibility
  [FiltersType.GRADES]?: Grade[] | Grade
  [FiltersType.DEADLINE]?: DeadlineValueType
  [FiltersType.LOCATION]?: LocationType
  [FiltersType.LOCATION_RADIUS]?: number
  [FiltersType.INCLUDE_ONLINE]?: boolean
  [FiltersType.ONLINE_ONLY]?: boolean
  [FiltersType.AVAILABILITY]?: AvailabilityType
  [FiltersType.ELIGIBILITY]?: Restriction[] | Restriction
  [FiltersType.NO_RESTRICTIONS]?: boolean[] | boolean
  [FiltersType.TYPE]?: LearningOpportunityType[] | LearningOpportunityType
}

export const initialState: FilterState = {
  [FiltersType.INTERESTS]: [],
  [FiltersType.SELECTIVE]: [],
  [FiltersType.FINANCIAL_ACCESSIBILITY]: [],
  [FiltersType.GRADES]: [],
  [FiltersType.ELIGIBILITY]: [
    Restriction.FirstGen,
    Restriction.LowIncome,
    Restriction.UnderRepresentedMinority,
    Restriction.UsCitizen,
    Restriction.UsResident,
  ],
  [FiltersType.INCLUDE_ONLINE]: true,
  [FiltersType.ONLINE_ONLY]: false,
  [FiltersType.NO_RESTRICTIONS]: [true],
  [FiltersType.LOCATION_RADIUS]: radiusValues[1],
}

export type FilterPayload<K extends keyof FilterActionValueTypes> = {
  key: K
  value: FilterActionValueTypes[K]
}

export type SetAction = {
  type: FilterAction.Set
  payload: FilterState
}
export type UpdateAction<K extends keyof FilterActionValueTypes> = {
  type: FilterAction.Update
  payload: FilterPayload<K>
}
export type AddAction<K extends keyof FilterActionValueTypes> = {
  type: FilterAction.Add
  payload: FilterPayload<K>
}
export type RemoveAction<K extends keyof FilterActionValueTypes> = {
  type: FilterAction.Remove
  payload: FilterPayload<K>
}
export type ToggleAction<K extends keyof FilterActionValueTypes> = {
  type: FilterAction.Toggle
  payload: FilterPayload<K>
}

type FilterActionTypes<K extends keyof FilterActionValueTypes> =
  | SetAction
  | UpdateAction<K>
  | AddAction<K>
  | RemoveAction<K>
  | ToggleAction<K>

export const filterReducer = <K extends keyof FilterState>(
  state: FilterState,
  { type, payload }: FilterActionTypes<K>,
): FilterState => {
  // Early return for either SET OR UPDATE
  if (type === FilterAction.Set) {
    return payload
  } else if (type === FilterAction.Update) {
    return {
      ...state,
      [payload.key]: payload.value,
    }
  }

  // Create new value for ADD or REMOVE
  let currentValue: Set<FilterState[K]> = new Set()
  const values = state[payload.key]
  if (Array.isArray(values)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentValue = new Set(values as any)
  } else if (values) {
    currentValue.add(values)
  }

  if (
    type === FilterAction.Add ||
    (type === FilterAction.Toggle && !currentValue.has(payload.value))
  ) {
    currentValue.add(payload.value)
  } else if (
    type === FilterAction.Remove ||
    (type === FilterAction.Toggle && currentValue.has(payload.value))
  ) {
    currentValue.delete(payload.value)
  }

  return {
    ...state,
    [payload.key]: Array.from(currentValue),
  }
}

export function createSetFilterAction(value: FilterState): SetAction {
  return {
    type: FilterAction.Set,
    payload: value,
  }
}

export function createUpdateFilterAction<
  K extends keyof FilterActionValueTypes,
>(key: K, value: FilterActionValueTypes[K]): UpdateAction<K> {
  return {
    type: FilterAction.Update,
    payload: {
      key,
      value,
    },
  }
}

export function createAddFilterAction<K extends keyof FilterActionValueTypes>(
  key: K,
  value: FilterActionValueTypes[K],
): AddAction<K> {
  return {
    type: FilterAction.Add,
    payload: {
      key,
      value,
    },
  }
}

export function createRemoveFilterAction<
  K extends keyof FilterActionValueTypes,
>(key: K, value: FilterActionValueTypes[K]): RemoveAction<K> {
  return {
    type: FilterAction.Remove,
    payload: {
      key,
      value,
    },
  }
}

export function createToggleFilterAction<
  K extends keyof FilterActionValueTypes,
>(key: K, value: FilterActionValueTypes[K]): ToggleAction<K> {
  return {
    type: FilterAction.Toggle,
    payload: {
      key,
      value,
    },
  }
}
