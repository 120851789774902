import { MouseEvent, useCallback } from "react"

import {
  referralKey,
  useReferral,
  utmCampaignKey,
  utmMediumKey,
  utmSourceKey,
} from "./useReferral"

type LinkCopyOptions = {
  blurTime?: number
  share?: boolean
  onClose: () => void
  onToggle: () => void
}

export const useLinkCopy = ({
  blurTime = 900,
  share = false,
  onToggle,
  onClose,
}: LinkCopyOptions) => {
  const referral = useReferral()

  return useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      if (!window) {
        return
      }
      e.stopPropagation()
      onToggle()
      setTimeout(() => {
        onClose()
      }, blurTime)

      const params = new URLSearchParams(window.location.search)
      if (referral) {
        params.set("utm_source", utmSourceKey)
        params.set("utm_medium", utmMediumKey)
        params.set("utm_campaign", utmCampaignKey)
        params.set(referralKey, referral)
      }

      let link = `${window.location.origin}${
        Array.from(params).length ? "?" : ""
      }${params.toString()}`

      if (share || !referral) {
        link = window.location.href
      }
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(link)
        return
      }

      return document.execCommand("copy", true, link)
    },
    [referral],
  )
}
