import { RefObject, useEffect, useMemo, useRef, useState } from "react"

interface IntersectionObserverOptions {
  root: null
  rootMargin: string
  threshold: number
}

export const useElementOnScreen = (
  options: IntersectionObserverOptions,
  targetRef: RefObject<HTMLDivElement>,
): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const observer = useRef<IntersectionObserver | undefined>()

  const optionsMemo = useMemo((): IntersectionObserverOptions => {
    return options
  }, [options])

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      optionsMemo,
    )
  })

  useEffect(() => {
    if (!observer.current) {
      return
    }
    const currentTarget = targetRef.current
    if (currentTarget) {
      observer.current.observe(currentTarget)
    }

    return () => {
      if (!observer.current) {
        return
      }
      if (currentTarget) {
        observer.current.unobserve(currentTarget)
      }
    }
  }, [targetRef, optionsMemo])

  return isVisible
}
