import { Container, Flex, Heading, LinkBox, LinkOverlay } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Logo } from "./Logo";
import { breakpoints, useWindowSize } from "../hooks";
import SnowdayMenu from "../menu/SnowdayMenu";
import { I18nTypes } from "@/types";
interface Props {
  title?: keyof I18nTypes["titles"]["header-titles"];
  variant?: boolean;
  onClick?: () => void;
}
const variantStyle = {
  logo: "snow.orange-medium",
  title: "snow.orange-dark",
  icons: "snow.orange",
  background: "snow.orange-extra-light"
};
const normalStyle = {
  logo: "snow.blue-medium",
  title: "snow.blue-dark",
  icons: "snow.blue",
  background: "snow.blue-light"
};
export const Header = ({
  title,
  variant,
  onClick
}: Props) => {
  const {
    t
  } = useTranslation("titles");
  const {
    pathname
  } = useRouter();
  const {
    width
  } = useWindowSize();
  const colorScheme = useMemo(() => {
    return variant ? variantStyle : normalStyle;
  }, [variant]);
  const showName = useMemo(() => pathname === "/" || title === undefined, [pathname, title]);
  const isExtraLargeScreen = width && width > breakpoints.xl;
  const isSearchResults = pathname.startsWith("/search/results");
  const isListDetail = pathname.startsWith("/lists");
  const containerWidth = isExtraLargeScreen && (isSearchResults || isListDetail) ? "120ch" : undefined;
  return <Flex backgroundColor={colorScheme.background} data-sentry-element="Flex" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <Container maxWidth={containerWidth} data-sentry-element="Container" data-sentry-source-file="Header.tsx">
        <Flex py={{
        base: 2,
        md: 2,
        lg: 3
      }} direction="row" justifyContent="space-between" as="header" data-sentry-element="Flex" data-sentry-source-file="Header.tsx">
          <LinkBox style={{
          display: "inline-flex",
          alignItems: "center"
        }} data-sentry-element="LinkBox" data-sentry-source-file="Header.tsx">
            <LinkOverlay as={onClick ? undefined : Link} onClick={onClick ? onClick : () => null} href={onClick ? undefined : "/"} cursor="pointer" data-sentry-element="LinkOverlay" data-sentry-source-file="Header.tsx">
              <Flex direction="row" gap="2" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="Header.tsx">
                <Logo color={colorScheme.logo} showName={showName} data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
                {!showName && title && <Heading variant="h3" display={{
                base: "inline-block",
                lg: "none"
              }} color={colorScheme.title}>
                    {t((`header-titles.${title}` as const))}
                  </Heading>}
              </Flex>
            </LinkOverlay>
          </LinkBox>

          <Flex direction="row" gap="2" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="Header.tsx">
            <SnowdayMenu data-sentry-element="SnowdayMenu" data-sentry-source-file="Header.tsx" />
          </Flex>
        </Flex>
      </Container>
    </Flex>;
};