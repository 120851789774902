import { Icon, IconProps } from "@chakra-ui/react";
const CalendarIcon = (props: IconProps) => <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="CalendarIcon" data-sentry-source-file="Calendar.tsx">
    <g clipPath="url(#clip0_4073_154625)" data-sentry-element="g" data-sentry-source-file="Calendar.tsx">
      <path d="M9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12ZM17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12ZM19 4H18V3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V4H8V3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Calendar.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Calendar.tsx">
      <clipPath id="clip0_4073_154625" data-sentry-element="clipPath" data-sentry-source-file="Calendar.tsx">
        <rect width={24} height={24} fill="white" data-sentry-element="rect" data-sentry-source-file="Calendar.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default CalendarIcon;