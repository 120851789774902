import { Icon, IconProps } from "@chakra-ui/react";
const CommunityIcon = (props: IconProps) => <Icon width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="CommunityIcon" data-sentry-source-file="Community.tsx">
    <g clipPath="url(#clip0_4068_149712)" data-sentry-element="g" data-sentry-source-file="Community.tsx">
      <path d="M35.4727 34.4823V18.6265C35.4727 18.381 35.2737 18.182 35.0282 18.182H33.1805C33.1805 13.9263 33.1805 11.5403 33.1805 7.28458C33.1805 7.03912 32.9815 6.84014 32.736 6.84014H31.9776V4.8259C31.9776 4.58043 31.7786 4.38145 31.5332 4.38145H29.5206V1.96221C29.5206 1.71675 29.3216 1.51776 29.0761 1.51776H25.9978C25.7524 1.51776 25.5534 1.71675 25.5534 1.96221V17.3394H23.6366V11.0361C23.6366 10.9183 23.5898 10.8052 23.5064 10.7219L21.7301 8.9455C21.6468 8.86218 21.5337 8.81536 21.4158 8.81536H19.6414C19.3959 8.81536 19.1969 9.01434 19.1969 9.2598V19.9055H17.2523V3.75046C17.2523 3.505 17.0533 3.30602 16.8078 3.30602H12.0045C11.7591 3.30602 11.5601 3.505 11.5601 3.75046V11.1603H10.4636C10.2181 11.1603 10.0191 11.3593 10.0191 11.6047V13.4527H8.10928V8.00528L4.11898 4.01498V15.7667H2.84301C2.59755 15.7667 2.39857 15.9656 2.39857 16.2111V20.9123H0.97179C0.726329 20.9123 0.527344 21.1113 0.527344 21.3568V34.4823H35.4727Z" fill="white" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.527344 27.3944C0.818588 27.3944 1.05469 27.1583 1.05469 26.8671V21.4397H2.39857C2.68981 21.4397 2.92591 21.2036 2.92591 20.9124V16.294H4.11898C4.41022 16.294 4.64632 16.0579 4.64632 15.7667V5.28814L7.58194 8.22375V13.4527C7.58194 13.744 7.81804 13.9801 8.10928 13.9801H10.0191C10.3104 13.9801 10.5465 13.744 10.5465 13.4527V11.6876H11.5601C11.8513 11.6876 12.0874 11.4515 12.0874 11.1602V3.8334H13.2004C13.4916 3.8334 13.7277 3.5973 13.7277 3.30605C13.7277 3.01481 13.4916 2.77871 13.2004 2.77871H12.0045C11.4678 2.77871 11.0327 3.2138 11.0327 3.7505V10.6329H10.4636C9.92685 10.6329 9.49177 11.068 9.49177 11.6047V12.9254H8.63662V8.00532C8.63662 7.86546 8.58107 7.73133 8.48217 7.63243L4.49186 3.64213C4.34105 3.49131 4.11423 3.44619 3.91717 3.52781C3.72012 3.60944 3.59163 3.80173 3.59163 4.01501V15.2393H2.84302C2.30631 15.2393 1.87123 15.6744 1.87123 16.2111V20.385H0.971789C0.435086 20.385 0 20.8201 0 21.3568V26.8671C0 27.1583 0.2361 27.3944 0.527344 27.3944Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 34.4823C0 34.7735 0.2361 35.0096 0.527344 35.0096H35.4727C35.7639 35.0096 36 34.7735 36 34.4823V18.6265C36 18.0898 35.5649 17.6547 35.0282 17.6547H33.7078V13.9766C33.7078 13.6853 33.4717 13.4492 33.1805 13.4492C32.8892 13.4492 32.6531 13.6853 32.6531 13.9766V18.182C32.6531 18.4733 32.8892 18.7094 33.1805 18.7094H34.9453V33.955H1.05469V29.3679C1.05469 29.0767 0.818588 28.8406 0.527344 28.8406C0.2361 28.8406 0 29.0767 0 29.3679V34.4823Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7249 19.9055C16.7249 20.1967 16.961 20.4328 17.2522 20.4328H19.1968C19.4881 20.4328 19.7242 20.1967 19.7242 19.9055V9.34262H21.3813L23.1092 11.0705V17.3394C23.1092 17.6306 23.3453 17.8667 23.6366 17.8667H25.5533C25.8445 17.8667 26.0806 17.6306 26.0806 17.3394V2.0451H28.9932V4.38144C28.9932 4.67269 29.2293 4.90879 29.5205 4.90879H31.4502V6.84013C31.4502 7.13137 31.6863 7.36747 31.9775 7.36747H32.653V11.4697C32.653 11.761 32.8891 11.9971 33.1804 11.9971C33.4716 11.9971 33.7077 11.761 33.7077 11.4697V7.28457C33.7077 6.74787 33.2726 6.31279 32.7359 6.31279H32.5049V4.82589C32.5049 4.28924 32.0698 3.8541 31.5332 3.8541H30.0479V1.9622C30.0479 1.4255 29.6128 0.99041 29.0761 0.99041H25.9977C25.461 0.99041 25.0259 1.4255 25.0259 1.9622V16.8121H24.1639V11.0361C24.1639 10.7785 24.0616 10.5312 23.8792 10.349C23.8792 10.3489 23.8791 10.3489 23.8791 10.3488L22.103 8.57276C21.9207 8.39033 21.6735 8.28793 21.4158 8.28793H19.6413C19.1046 8.28793 18.6695 8.72302 18.6695 9.25972V19.3781H17.7795V3.75046C17.7795 3.21375 17.3445 2.77867 16.8078 2.77867H15.7012C15.4099 2.77867 15.1738 3.01477 15.1738 3.30601C15.1738 3.59726 15.4099 3.83336 15.7012 3.83336H16.7249V19.9055ZM21.3571 9.31838C21.3571 9.31839 21.3571 9.3184 21.3571 9.31841L21.3571 9.31838Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.81494 35.0094C9.10619 35.0094 9.34229 34.7733 9.34229 34.4821V29.8441C9.34229 29.5529 9.10619 29.3168 8.81494 29.3168C8.5237 29.3168 8.2876 29.5529 8.2876 29.8441V34.4821C8.2876 34.7733 8.5237 35.0094 8.81494 35.0094Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0752 35.0094C15.3664 35.0094 15.6025 34.7733 15.6025 34.4821V29.8441C15.6025 29.5529 15.3664 29.3168 15.0752 29.3168C14.784 29.3168 14.5479 29.5529 14.5479 29.8441V34.4821C14.5479 34.7733 14.784 35.0094 15.0752 35.0094Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.335 35.0094C21.6262 35.0094 21.8623 34.7733 21.8623 34.4821V29.8441C21.8623 29.5529 21.6262 29.3168 21.335 29.3168C21.0437 29.3168 20.8076 29.5529 20.8076 29.8441V34.4821C20.8076 34.7733 21.0437 35.0094 21.335 35.0094Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5947 35.0094C27.886 35.0094 28.1221 34.7733 28.1221 34.4821V29.8441C28.1221 29.5529 27.886 29.3168 27.5947 29.3168C27.3035 29.3168 27.0674 29.5529 27.0674 29.8441V34.4821C27.0674 34.7733 27.3035 35.0094 27.5947 35.0094Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.1309 9.34265C20.4221 9.34265 20.6582 9.10655 20.6582 8.81531V5.97792C20.6582 5.68667 20.4221 5.45057 20.1309 5.45057C19.8396 5.45057 19.6035 5.68667 19.6035 5.97792V8.81531C19.6035 9.10655 19.8396 9.34265 20.1309 9.34265Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.10937 8.53259C8.40062 8.53259 8.63672 8.29649 8.63672 8.00525V5.50993C8.63672 5.21868 8.40062 4.98258 8.10937 4.98258C7.81813 4.98258 7.58203 5.21868 7.58203 5.50993V8.00525C7.58203 8.29649 7.81813 8.53259 8.10937 8.53259Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.11914 4.5423C4.41038 4.5423 4.64648 4.3062 4.64648 4.01495V1.99952C4.64648 1.70827 4.41038 1.47217 4.11914 1.47217C3.8279 1.47217 3.5918 1.70827 3.5918 1.99952V4.01495C3.5918 4.3062 3.8279 4.5423 4.11914 4.5423Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M15.0734 23.9679L14.8087 20.9946C14.7216 20.0156 13.9013 19.2651 12.9184 19.2651H10.9715C9.98857 19.2651 9.16831 20.0156 9.08119 20.9946L8.81647 23.9679C8.79671 24.19 8.97157 24.3811 9.19454 24.3811H10.0956V28.515C10.0956 28.9342 10.4354 29.2741 10.8547 29.2741H13.0352C13.4544 29.2741 13.7943 28.9342 13.7943 28.515V24.3811H14.6954C14.9183 24.3811 15.0932 24.19 15.0734 23.9679Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M21.3337 23.9679L21.069 20.9946C20.9818 20.0156 20.1616 19.2651 19.1787 19.2651H17.2317C16.2488 19.2651 15.4286 20.0156 15.3414 20.9946L15.0767 23.9679C15.057 24.19 15.2318 24.3811 15.4548 24.3811H16.3558V28.515C16.3558 28.9342 16.6957 29.2741 17.1149 29.2741H19.2955C19.7147 29.2741 20.0546 28.9342 20.0546 28.515V24.3811H20.9556C21.1785 24.3811 21.3534 24.19 21.3337 23.9679Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M27.5935 23.9679L27.3287 20.9946C27.2416 20.0156 26.4213 19.2651 25.4384 19.2651H23.4915C22.5086 19.2651 21.6883 20.0156 21.6012 20.9946L21.3365 23.9679C21.3167 24.19 21.4916 24.3811 21.7146 24.3811H22.6156V28.515C22.6156 28.9342 22.9554 29.2741 23.3747 29.2741H25.5552C25.9744 29.2741 26.3143 28.9342 26.3143 28.515V24.3811H27.2154C27.4383 24.3811 27.6132 24.19 27.5935 23.9679Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8547 28.7468C10.7267 28.7468 10.623 28.643 10.623 28.515V24.3811C10.623 24.0899 10.3869 23.8538 10.0956 23.8538H9.35609L9.6065 21.0414C9.6694 20.3344 10.2617 19.7925 10.9715 19.7925H12.9185C13.6283 19.7925 14.2206 20.3344 14.2835 21.0413L14.5339 23.8538H13.7943C13.5031 23.8538 13.267 24.0899 13.267 24.3811V28.515C13.267 28.643 13.1632 28.7468 13.0353 28.7468H10.8547ZM9.56829 28.515C9.56829 29.2254 10.1442 29.8015 10.8547 29.8015H13.0353C13.7457 29.8015 14.3217 29.2255 14.3217 28.515V24.9085H14.6954C15.228 24.9085 15.6459 24.4517 15.5987 23.9212L15.334 20.9479C15.334 20.9479 15.334 20.9479 15.334 20.9479C15.2227 19.6968 14.1745 18.7378 12.9185 18.7378H10.9715C9.71552 18.7378 8.66731 19.6967 8.55596 20.9478C8.55596 20.9478 8.55597 20.9478 8.55596 20.9478L8.29124 23.9211C8.24404 24.4516 8.66185 24.9085 9.19457 24.9085H9.56829V28.515Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.115 28.7468C16.987 28.7468 16.8832 28.643 16.8832 28.515V24.3811C16.8832 24.0899 16.6471 23.8538 16.3559 23.8538H15.6163L15.8668 21.0414C15.9297 20.3344 16.522 19.7925 17.2318 19.7925H19.1787C19.8885 19.7925 20.4808 20.3344 20.5437 21.0413L20.7941 23.8538H20.0546C19.7634 23.8538 19.5273 24.0899 19.5273 24.3811V28.515C19.5273 28.643 19.4235 28.7468 19.2955 28.7468H17.115ZM15.8285 28.515C15.8285 29.2254 16.4044 29.8015 17.115 29.8015H19.2955C20.006 29.8015 20.5819 29.2255 20.5819 28.515V24.9085H20.9557C21.4882 24.9085 21.9062 24.4517 21.859 23.9212L21.5943 20.9479C21.5943 20.9479 21.5943 20.9479 21.5943 20.9479C21.4829 19.6968 20.4347 18.7378 19.1787 18.7378H17.2318C15.9758 18.7378 14.9276 19.6967 14.8162 20.9478C14.8162 20.9478 14.8162 20.9478 14.8162 20.9478L14.5515 23.9211C14.5043 24.4516 14.9221 24.9085 15.4548 24.9085H15.8285V28.515Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3747 28.7468C23.2467 28.7468 23.143 28.643 23.143 28.515V24.3811C23.143 24.0899 22.9069 23.8538 22.6156 23.8538H21.8761L22.1265 21.0414C22.1894 20.3344 22.7817 19.7925 23.4915 19.7925H25.4385C26.1483 19.7925 26.7406 20.3344 26.8035 21.0413L27.0539 23.8538H26.3144C26.0231 23.8538 25.787 24.0899 25.787 24.3811V28.515C25.787 28.643 25.6832 28.7468 25.5553 28.7468H23.3747ZM22.0883 28.515C22.0883 29.2254 22.6642 29.8015 23.3747 29.8015H25.5553C26.2657 29.8015 26.8417 29.2255 26.8417 28.515V24.9085H27.2154C27.748 24.9085 28.166 24.4517 28.1188 23.9212L27.854 20.9479C27.854 20.9479 27.854 20.9479 27.854 20.9479C27.7427 19.6968 26.6945 18.7378 25.4385 18.7378H23.4915C22.2355 18.7378 21.1873 19.6967 21.076 20.9478C21.076 20.9478 21.076 20.9478 21.076 20.9478L20.8113 23.9211C20.7641 24.4516 21.1819 24.9085 21.7146 24.9085H22.0883V28.515Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M11.9436 29.4665L11.6788 26.4932C11.5917 25.5142 10.7714 24.7637 9.78854 24.7637H7.84159C6.85869 24.7637 6.03843 25.5142 5.95131 26.4932L5.68658 29.4665C5.66682 29.6886 5.84169 29.8797 6.06465 29.8797H6.96571V33.723C6.96571 34.1422 7.30553 34.4821 7.7248 34.4821H9.90533C10.3245 34.4821 10.6644 34.1422 10.6644 33.723V29.8797H11.5655C11.7884 29.8797 11.9633 29.6886 11.9436 29.4665Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M18.2038 29.4665L17.9391 26.4932C17.852 25.5142 17.0317 24.7637 16.0488 24.7637H14.1018C13.1189 24.7637 12.2987 25.5142 12.2115 26.4932L11.9468 29.4665C11.9271 29.6886 12.1019 29.8797 12.3249 29.8797H13.226V33.723C13.226 34.1422 13.5658 34.4821 13.9851 34.4821H16.1656C16.5848 34.4821 16.9247 34.1422 16.9247 33.723V29.8797H17.8257C18.0486 29.8797 18.2236 29.6886 18.2038 29.4665Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M24.4636 29.4665L24.1988 26.4932C24.1117 25.5142 23.2915 24.7637 22.3086 24.7637H20.3616C19.3787 24.7637 18.5584 25.5142 18.4713 26.4932L18.2066 29.4665C18.1868 29.6886 18.3617 29.8797 18.5847 29.8797H19.4857V33.723C19.4857 34.1422 19.8255 34.4821 20.2448 34.4821H22.4254C22.8446 34.4821 23.1844 34.1422 23.1844 33.723V29.8797H24.0855C24.3084 29.8797 24.4833 29.6886 24.4636 29.4665Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M30.7233 29.4665L30.4586 26.4932C30.3715 25.5142 29.5512 24.7637 28.5683 24.7637H26.6214C25.6385 24.7637 24.8182 25.5142 24.731 26.4932L24.4664 29.4665C24.4466 29.6886 24.6215 29.8797 24.8444 29.8797H25.7455V33.723C25.7455 34.1422 26.0853 34.4821 26.5046 34.4821H28.6851C29.1043 34.4821 29.4442 34.1422 29.4442 33.723V29.8797H30.3453C30.5682 29.8797 30.7431 29.6886 30.7233 29.4665Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M13.8706 17.3393C13.8706 18.4029 13.0084 19.2651 11.9448 19.2651C10.8813 19.2651 10.019 18.4029 10.019 17.3393C10.019 16.2758 10.8813 15.4136 11.9448 15.4136C13.0084 15.4136 13.8706 16.2758 13.8706 17.3393Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M20.1309 17.3393C20.1309 18.4029 19.2687 19.2651 18.2051 19.2651C17.1415 19.2651 16.2793 18.4029 16.2793 17.3393C16.2793 16.2758 17.1415 15.4136 18.2051 15.4136C19.2687 15.4136 20.1309 16.2758 20.1309 17.3393Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M26.3906 17.3393C26.3906 18.4029 25.5285 19.2651 24.4649 19.2651C23.4013 19.2651 22.5391 18.4029 22.5391 17.3393C22.5391 16.2758 23.4013 15.4136 24.4649 15.4136C25.5285 15.4136 26.3906 16.2758 26.3906 17.3393Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M10.7407 22.8379C10.7407 23.9015 9.87857 24.7637 8.81495 24.7637C7.7514 24.7637 6.88916 23.9015 6.88916 22.8379C6.88916 21.7744 7.7514 20.9122 8.81495 20.9122C9.87857 20.9122 10.7407 21.7744 10.7407 22.8379Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M17.001 22.8379C17.001 23.9015 16.1388 24.7637 15.0752 24.7637C14.0117 24.7637 13.1494 23.9015 13.1494 22.8379C13.1494 21.7744 14.0117 20.9122 15.0752 20.9122C16.1388 20.9122 17.001 21.7744 17.001 22.8379Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M23.2608 22.8379C23.2608 23.9015 22.3986 24.7637 21.335 24.7637C20.2714 24.7637 19.4092 23.9015 19.4092 22.8379C19.4092 21.7744 20.2714 20.9122 21.335 20.9122C22.3986 20.9122 23.2608 21.7744 23.2608 22.8379Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path d="M29.5205 22.8379C29.5205 23.9015 28.6584 24.7637 27.5947 24.7637C26.5312 24.7637 25.6689 23.9015 25.6689 22.8379C25.6689 21.7744 26.5312 20.9122 27.5947 20.9122C28.6584 20.9122 29.5205 21.7744 29.5205 22.8379Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.4917 17.3393C9.4917 18.6941 10.59 19.7925 11.9448 19.7925C13.2997 19.7925 14.398 18.6941 14.398 17.3393C14.398 15.9846 13.2997 14.8862 11.9448 14.8862C10.59 14.8862 9.4917 15.9846 9.4917 17.3393ZM11.9448 18.7378C11.1725 18.7378 10.5464 18.1117 10.5464 17.3393C10.5464 16.567 11.1725 15.9409 11.9448 15.9409C12.7172 15.9409 13.3433 16.567 13.3433 17.3393C13.3433 18.1117 12.7172 18.7378 11.9448 18.7378Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.752 17.3393C15.752 18.6941 16.8503 19.7925 18.2051 19.7925C19.56 19.7925 20.6582 18.6941 20.6582 17.3393C20.6582 15.9846 19.56 14.8862 18.2051 14.8862C16.8503 14.8862 15.752 15.9846 15.752 17.3393ZM18.2051 18.7378C17.4328 18.7378 16.8066 18.1117 16.8066 17.3393C16.8066 16.567 17.4328 15.9409 18.2051 15.9409C18.9774 15.9409 19.6035 16.567 19.6035 17.3393C19.6035 18.1117 18.9774 18.7378 18.2051 18.7378Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.0117 17.3393C22.0117 18.6941 23.1101 19.7925 24.4649 19.7925C25.8197 19.7925 26.918 18.6941 26.918 17.3393C26.918 15.9846 25.8197 14.8862 24.4649 14.8862C23.1101 14.8862 22.0117 15.9846 22.0117 17.3393ZM24.4649 18.7378C23.6925 18.7378 23.0664 18.1117 23.0664 17.3393C23.0664 16.567 23.6925 15.9409 24.4649 15.9409C25.2372 15.9409 25.8633 16.567 25.8633 17.3393C25.8633 18.1117 25.2372 18.7378 24.4649 18.7378Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.36182 22.8379C6.36182 24.1927 7.46016 25.2911 8.81495 25.2911C10.1698 25.2911 11.2681 24.1927 11.2681 22.8379C11.2681 21.4832 10.1698 20.3848 8.81495 20.3848C7.46016 20.3848 6.36182 21.4832 6.36182 22.8379ZM8.81495 24.2364C8.04265 24.2364 7.4165 23.6102 7.4165 22.8379C7.4165 22.0656 8.04265 21.4395 8.81495 21.4395C9.58731 21.4395 10.2134 22.0656 10.2134 22.8379C10.2134 23.6103 9.58731 24.2364 8.81495 24.2364Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6221 22.8379C12.6221 24.1927 13.7204 25.2911 15.0752 25.2911C16.4301 25.2911 17.5283 24.1927 17.5283 22.8379C17.5283 21.4832 16.4301 20.3848 15.0752 20.3848C13.7204 20.3848 12.6221 21.4832 12.6221 22.8379ZM15.0752 24.2364C14.3029 24.2364 13.6768 23.6102 13.6768 22.8379C13.6768 22.0656 14.3029 21.4395 15.0752 21.4395C15.8476 21.4395 16.4736 22.0656 16.4736 22.8379C16.4736 23.6103 15.8476 24.2364 15.0752 24.2364Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.8818 22.8379C18.8818 24.1927 19.9802 25.2911 21.335 25.2911C22.6898 25.2911 23.7881 24.1927 23.7881 22.8379C23.7881 21.4832 22.6898 20.3848 21.335 20.3848C19.9802 20.3848 18.8818 21.4832 18.8818 22.8379ZM21.335 24.2364C20.5627 24.2364 19.9365 23.6102 19.9365 22.8379C19.9365 22.0656 20.5627 21.4395 21.335 21.4395C22.1073 21.4395 22.7334 22.0656 22.7334 22.8379C22.7334 23.6103 22.1073 24.2364 21.335 24.2364Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.1416 22.8379C25.1416 24.1927 26.2399 25.2911 27.5947 25.2911C28.9496 25.2911 30.0479 24.1927 30.0479 22.8379C30.0479 21.4832 28.9496 20.3848 27.5947 20.3848C26.2399 20.3848 25.1416 21.4832 25.1416 22.8379ZM27.5947 24.2364C26.8224 24.2364 26.1963 23.6102 26.1963 22.8379C26.1963 22.0656 26.8224 21.4395 27.5947 21.4395C28.3671 21.4395 28.9932 22.0656 28.9932 22.8379C28.9932 23.6103 28.3671 24.2364 27.5947 24.2364Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.72484 33.9548C7.59684 33.9548 7.49309 33.851 7.49309 33.723V29.8797C7.49309 29.5885 7.25699 29.3524 6.96575 29.3524H6.22621L6.47661 26.54C6.53952 25.833 7.13183 25.2911 7.84163 25.2911H9.78858C10.4984 25.2911 11.0907 25.833 11.1536 26.54L11.404 29.3524H10.6645C10.3732 29.3524 10.1371 29.5885 10.1371 29.8797V33.723C10.1371 33.851 10.0333 33.9548 9.90537 33.9548H7.72484ZM6.4384 33.723C6.4384 34.4334 7.01429 35.0095 7.72484 35.0095H9.90537C10.6158 35.0095 11.1918 34.4335 11.1918 33.723V30.4071H11.5655C12.0981 30.4071 12.5161 29.9503 12.4689 29.4198L12.2041 26.4465C12.2041 26.4465 12.2041 26.4465 12.2041 26.4465C12.0928 25.1954 11.0446 24.2364 9.78858 24.2364H7.84163C6.58564 24.2364 5.53742 25.1953 5.42608 26.4464C5.42608 26.4465 5.42608 26.4464 5.42608 26.4464L5.16136 29.4197C5.11416 29.9502 5.53197 30.4071 6.06469 30.4071H6.4384V33.723Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9851 33.9548C13.8571 33.9548 13.7533 33.851 13.7533 33.723V29.8797C13.7533 29.5885 13.5172 29.3524 13.226 29.3524H12.4865L12.7368 26.54C12.7368 26.54 12.7368 26.54 12.7368 26.54C12.7998 25.833 13.3921 25.2911 14.1019 25.2911H16.0488C16.7586 25.2911 17.3509 25.833 17.4138 26.54L17.6643 29.3524H16.9247C16.6335 29.3524 16.3974 29.5885 16.3974 29.8797V33.723C16.3974 33.851 16.2936 33.9548 16.1656 33.9548H13.9851ZM12.6987 33.723C12.6987 34.4334 13.2745 35.0095 13.9851 35.0095H16.1656C16.8761 35.0095 17.4521 34.4335 17.4521 33.723V30.4071H17.8258C18.3584 30.4071 18.7763 29.9503 18.7291 29.4198L18.4644 26.4465C18.4644 26.4465 18.4644 26.4465 18.4644 26.4465C18.353 25.1954 17.3048 24.2364 16.0488 24.2364H14.1019C12.8459 24.2364 11.7977 25.1954 11.6863 26.4464L11.4216 29.4198C11.3744 29.9502 11.7922 30.4071 12.3249 30.4071H12.6987V33.723Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2449 33.9548C20.1169 33.9548 20.0131 33.851 20.0131 33.723V29.8797C20.0131 29.5885 19.777 29.3524 19.4858 29.3524H18.7462L18.9966 26.54C19.0595 25.833 19.6519 25.2911 20.3616 25.2911H22.3086C23.0184 25.2911 23.6107 25.833 23.6736 26.54L23.924 29.3524H23.1845C22.8932 29.3524 22.6571 29.5885 22.6571 29.8797V33.723C22.6571 33.851 22.5533 33.9548 22.4254 33.9548H20.2449ZM18.9584 33.723C18.9584 34.4334 19.5343 35.0095 20.2449 35.0095H22.4254C23.1358 35.0095 23.7118 34.4335 23.7118 33.723V30.4071H24.0855C24.6181 30.4071 25.0361 29.9503 24.9889 29.4198L24.7242 26.4465C24.7241 26.4465 24.7242 26.4465 24.7242 26.4465C24.6128 25.1954 23.5646 24.2364 22.3086 24.2364H20.3616C19.1057 24.2364 18.0574 25.1953 17.9461 26.4464C17.9461 26.4465 17.9461 26.4464 17.9461 26.4464L17.6814 29.4197C17.6342 29.9502 18.052 30.4071 18.5847 30.4071H18.9584V33.723Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5046 33.9547C26.3766 33.9547 26.2729 33.851 26.2729 33.723V29.8797C26.2729 29.5884 26.0368 29.3523 25.7455 29.3523H25.006L25.2564 26.5399C25.3193 25.833 25.9116 25.291 26.6214 25.291H28.5684C29.2781 25.291 29.8705 25.833 29.9334 26.5399L30.1838 29.3523H29.4442C29.153 29.3523 28.9169 29.5884 28.9169 29.8797V33.723C28.9169 33.851 28.8131 33.9547 28.6852 33.9547H26.5046ZM25.2182 33.723C25.2182 34.4335 25.7941 35.0094 26.5046 35.0094H28.6852C29.3956 35.0094 29.9716 34.4335 29.9716 33.723V30.407H30.3453C30.8779 30.407 31.2958 29.9502 31.2486 29.4197L30.9839 26.4464C30.9839 26.4464 30.9839 26.4464 30.9839 26.4464C30.8726 25.1954 29.8244 24.2363 28.5684 24.2363H26.6214C25.3654 24.2363 24.3172 25.1954 24.2059 26.4464C24.2059 26.4464 24.2059 26.4464 24.2059 26.4464L23.9411 29.4197C23.8939 29.9501 24.3118 30.407 24.8445 30.407H25.2182V33.723Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Community.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Community.tsx">
      <clipPath id="clip0_4068_149712" data-sentry-element="clipPath" data-sentry-source-file="Community.tsx">
        <rect width={36} height={36} fill="white" data-sentry-element="rect" data-sentry-source-file="Community.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default CommunityIcon;