import { extendTheme } from "@chakra-ui/react"
import { withProse } from "@nikolovlazar/chakra-ui-prose"

import { Button } from "./button"
import { checkboxTheme } from "./checkbox"
import { Container } from "./container"
import { Drawer } from "./drawer"
import { Heading } from "./heading"
import { inputTheme } from "./input"
import { Modal } from "./modal"
import { radioTheme } from "./radio"
import { Select } from "./select"
import { sliderTheme } from "./slider"
import { Spinner } from "./spinner"
import { Tag } from "./tag"
import { Text } from "./text"

const theme = extendTheme(
  {
    components: {
      Heading,
      Button,
      Spinner,
      Text,
      Tag,
      Container,
      Modal: Modal,
      Drawer: Drawer,
      Radio: radioTheme,
      Checkbox: checkboxTheme,
      Input: inputTheme,
      Select,
      Slider: sliderTheme,
    },
    fonts: {
      heading: `'Nunito', sans-serif`,
      body: `'Nunito', sans-serif`,
    },
    colors: {
      snow: {
        "blue-overlay": "rgba(36, 47, 80, 0.25)",
        "blue-extra-light": "#EFF6FD",
        "blue-light": "#E6F1FB",
        "blue-bg": "#F7FAFD",
        blue: "#62ABEA",
        "blue-hero": "#AAD0F2",
        "blue-light-medium": "#257AC3",
        "blue-medium": "#344372",
        "blue-dark": "#242F50",
        "orange-extra-light": "#FFF0E2",
        "orange-light": " #FFBC7C",
        orange: "#E17A19",
        "orange-medium": "#915217",
        "orange-dark": "#3D2B1A",
        bronze: "#915217",
        silver: "#6F768B",
        gold: "#FFBC02",
        errors: "#D1495B",
        "white-transparent": "rgba(255, 255, 255, 0.25)",
        divider: "rgba(36, 47, 80, 0.08)",
        "link-inactive": "rgba(36, 47, 80, 0.75)",
        "tag-timeline": "rgba(52, 67, 114, 0.08)",
        "home-bg": "#FAFCFE",
      },
    },
  },
  withProse({}),
)

export default theme
