import { isEmpty, isEqual, negate, omit, pickBy, size } from "lodash";
import { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import { usePagination } from "../../pagination/providers/PaginationProvider";
import { eligibilityOptions } from "../options";
import { createAddFilterAction, createRemoveFilterAction, createSetFilterAction, createToggleFilterAction, createUpdateFilterAction, FilterActionValueTypes, filterReducer, FilterState, initialState } from "../state/reducer";
import { FiltersType } from "../types";
import { handleFiltersType } from "@/featureFlags/utils";
import { useQuerySyncedValue } from "@/utils/hooks/useQuerySyncedValue";
import { handleURIFilter } from "@/utils/string";
type FilterHandler<K extends keyof FilterActionValueTypes> = (key: K, value: FilterActionValueTypes[K]) => void;
type SetHandler = (value: FilterState) => void;
type FilterContextType = {
  editedFilters: FilterState;
  appliedFilters: FilterState;
  count: number;
  hasUnappliedFilters: boolean;
  onSet: SetHandler;
  onUpdate: FilterHandler<keyof FilterActionValueTypes>;
  onAdd: FilterHandler<keyof FilterActionValueTypes>;
  onRemove: FilterHandler<keyof FilterActionValueTypes>;
  onToggle: FilterHandler<keyof FilterActionValueTypes>;
  onCancel: () => void;
  onApply: () => void;
  onClear: () => void;
};
export const FILTER_KEY = "filter";
const FilterContext = createContext<FilterContextType | undefined>(undefined);
const serializeFilters = (value: FilterState | undefined) => {
  if (!value || isEqual(value, initialState)) {
    return;
  }
  const enabledFilters = handleFiltersType(value);
  return JSON.stringify(enabledFilters);
};
const deserializeFilters = (param: string | undefined) => {
  const verifyFilter = {
    ...initialState,
    ...handleURIFilter(param)
  };
  return handleFiltersType(verifyFilter);
};
export const FilterProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [appliedFilters, setAppliedFilters] = useQuerySyncedValue(FILTER_KEY, serializeFilters, deserializeFilters, initialState);
  const {
    resetPageNumber
  } = usePagination();
  const [editedFilters, dispatchEditedFilters] = useReducer(filterReducer, appliedFilters);
  useEffect(() => {
    if (!isEqual(appliedFilters, editedFilters)) {
      onSet(appliedFilters);
    }
  }, [appliedFilters]);
  const hasUnappliedFilters = useMemo(() => {
    return !isEqual(editedFilters, appliedFilters);
  }, [editedFilters, appliedFilters]);
  const onSet = (value: FilterState) => {
    dispatchEditedFilters(createSetFilterAction(value));
  };
  const onUpdate = <K extends keyof FilterActionValueTypes,>(key: K, value: FilterActionValueTypes[K]) => {
    dispatchEditedFilters(createUpdateFilterAction(key, value));
  };
  const onAdd = <K extends keyof FilterActionValueTypes,>(key: K, value: FilterActionValueTypes[K]) => {
    dispatchEditedFilters(createAddFilterAction(key, value));
  };
  const onRemove = <K extends keyof FilterActionValueTypes,>(key: K, value: FilterActionValueTypes[K]) => {
    dispatchEditedFilters(createRemoveFilterAction(key, value));
  };
  const onToggle = <K extends keyof FilterActionValueTypes,>(key: K, value: FilterActionValueTypes[K]) => {
    dispatchEditedFilters(createToggleFilterAction(key, value));
  };
  const onCancel = () => {
    onSet(appliedFilters);
  };
  const onClear = () => {
    setAppliedFilters(initialState);
    onSet(initialState);
  };
  const onApply = () => {
    setAppliedFilters(editedFilters);
    resetPageNumber();
  };
  const filterCount = useMemo(() => {
    // Get the amount of active filters WITHUOT eligibility & noRestrictions
    // These are handled separately because of domain logic
    const activeFiltersWithoutEligibility = size(pickBy(omit(appliedFilters, [FiltersType.ELIGIBILITY, FiltersType.NO_RESTRICTIONS]), negate(isEmpty)));

    // Get the amount of active eligibility filters,
    // if there are any, add 1 to the count
    const inActiveEligibility = appliedFilters[FiltersType.ELIGIBILITY] && appliedFilters[FiltersType.ELIGIBILITY].length < eligibilityOptions.length || isEmpty(appliedFilters[FiltersType.NO_RESTRICTIONS]);
    return activeFiltersWithoutEligibility + (inActiveEligibility ? 1 : 0);
  }, [appliedFilters]);
  const value: FilterContextType = {
    editedFilters,
    appliedFilters,
    hasUnappliedFilters,
    count: filterCount,
    onSet,
    onUpdate,
    onAdd,
    onRemove,
    onToggle,
    onCancel,
    onApply,
    onClear
  };
  return <FilterContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="FilterProvider" data-sentry-source-file="FiltersProvider.tsx">{children}</FilterContext.Provider>;
};
export function useFilter() {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
}