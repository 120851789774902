import { inputAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  field: {
    position: "relative",
    "::-webkit-calendar-picker-indicator": {
      backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgMTJDOSAxMS40NDc3IDguNTUyMjggMTEgOCAxMUM3LjQ0NzcyIDExIDcgMTEuNDQ3NyA3IDEyQzcgMTIuNTUyMyA3LjQ0NzcyIDEzIDggMTNDOC41NTIyOCAxMyA5IDEyLjU1MjMgOSAxMlpNMTMgMTJDMTMgMTEuNDQ3NyAxMi41NTIzIDExIDEyIDExQzExLjQ0NzcgMTEgMTEgMTEuNDQ3NyAxMSAxMkMxMSAxMi41NTIzIDExLjQ0NzcgMTMgMTIgMTNDMTIuNTUyMyAxMyAxMyAxMi41NTIzIDEzIDEyWk0xNyAxMkMxNyAxMS40NDc3IDE2LjU1MjMgMTEgMTYgMTFDMTUuNDQ3NyAxMSAxNSAxMS40NDc3IDE1IDEyQzE1IDEyLjU1MjMgMTUuNDQ3NyAxMyAxNiAxM0MxNi41NTIzIDEzIDE3IDEyLjU1MjMgMTcgMTJaTTE5IDRIMThWM0MxOCAyLjQ0NzcyIDE3LjU1MjMgMiAxNyAyQzE2LjQ0NzcgMiAxNiAyLjQ0NzcyIDE2IDNWNEg4VjNDOCAyLjQ0NzcyIDcuNTUyMjggMiA3IDJDNi40NDc3MiAyIDYgMi40NDc3MiA2IDNWNEg1QzMuODkgNCAzLjAxIDQuOSAzLjAxIDZMMyAyMEMzIDIxLjEgMy44OSAyMiA1IDIySDE5QzIwLjEgMjIgMjEgMjEuMSAyMSAyMFY2QzIxIDQuOSAyMC4xIDQgMTkgNFpNMTkgMjBINVY5SDE5VjIwWiIgZmlsbD0iY3VycmVudENvbG9yIi8+Cjwvc3ZnPgo=")`,
      bgSize: "24px",
      bgPos: "center",
      padding: 0,
      bgColor: "snow.blue-light",
      color: "snow.blue-medium",
      cursor: "pointer",
      height: "100%",
      width: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      borderRightRadius: 5,
    },
  },
})

// define custom variant
const variants = {
  date: definePartsStyle({
    field: {
      fontSize: { base: 14, md: 16 },
      px: { base: 2, md: 3 },
      borderWidth: 1,
      _invalid: {
        borderColor: "snow.errors",
      },
    },
  }),
}

// export the component theme
export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
})
