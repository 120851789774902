import { defineStyleConfig } from "@chakra-ui/react"

export const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "24px", // <-- border radius is same for all variants and sizes
    color: "snow.blue-dark",
  },
  // Two sizes: sm and md
  sizes: {
    md: {
      fontSize: "md",
      lineHeight: "22px",
      py: 3, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      bg: "snow.blue",
      width: "100%",
      _hover: {
        _disabled: {
          bg: "snow.blue",
        },
      },
    },
    secondary: {
      bg: "transparent",
      border: "2px solid",
      borderColor: "snow.blue",
      width: "100%",
      color: "snow.blue",
    },
    invite: {
      width: "100%",
      borderRadius: "9999px",
      h: {
        base: "40px",
      },
      boxShadow:
        "0px 1px 2px rgba(52, 67, 114, 0.2), 0px 2px 4px rgba(52, 67, 114, 0.12)",
      bg: "snow.blue",
      _hover: {
        color: {
          lg: "white",
        },
        bg: {
          lg: "snow.blue-dark",
        },
      },
    },
    fab: {
      borderRadius: "9999px",
      py: 2,
      h: "56px",
      w: "56px",
      boxShadow:
        "0px 2px 4px rgba(52, 67, 114, 0.33), 0px 4px 8px rgba(52, 67, 114, 0.2)",
      bg: "snow.blue",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "primary",
  },
})
