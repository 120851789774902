import { defineStyleConfig } from "@chakra-ui/react"

export const Select = defineStyleConfig({
  variants: {
    filter: {
      field: {
        border: "1px solid",
        borderColor: "inherit",
        paddingLeft: 3,
      },
      icon: {
        bg: "snow.blue-light",
        w: 10,
        h: 10,
        color: "snow.blue-medium",
        right: 0,
        borderRightRadius: "base",
      },
    },
  },
  defaultProps: {
    variant: "filter",
  },
})
