import { useState, useEffect } from "react"

export interface Size {
  width: number | undefined
  height: number | undefined
}

// Derived from Chakra implementation. Range is from pixel value and upwards
export enum breakpoints {
  sm = 480,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1536,
}

export const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}
