export enum FiltersType {
  COLLEGE_CREDIT = "FILTER_COLLEGE_CREDIT",
  GRADES = "FILTER_GRADES",
  INTERESTS = "FILTER_INTERESTS",
  SELECTIVE = "FILTER_SELECTIVE",
  FINANCIAL_ACCESSIBILITY = "FILTER_FINANCIAL_ACCESSIBILITY",
  DEADLINE = "FILTER_DEADLINE",
  LOCATION = "FILTER_LOCATION",
  LOCATION_RADIUS = "FILTER_LOCATION_RADIUS",
  AVAILABILITY = "FILTER_AVAILABILITY",
  ELIGIBILITY = "FILTERS_ELIGIBILITY",
  NO_RESTRICTIONS = "FILTERS_NO_RESTRICTIONS",
  PROVIDERS = "FILTERS_PROVIDERS",
  QUERY = "QUERY",
  TYPE = "FILTER_TYPE",
  INCLUDE_ONLINE = "FILTER_INCLUDE_ONLINE",
  ONLINE_ONLY = "FILTER_ONLINE_ONLY",
  ONE_ON_ONE = "FILTER_ONE_ON_ONE",
}

export enum FilterCategoriesType {
  QUICK_FILTERS = "FILTERS_QUICK_FILTERS",
  ADVANCED_CRITERIA = "FILTERS_ADVANCED_CRITERIA",
  ELIGIBILITY_RESTRICTIONS = "FILTERS_ELIGIBILITY_RESTRICTIONS",
}
