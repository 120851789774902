export const not =
  <T>(fn: (v: T) => boolean) =>
  (v: T) =>
    !fn(v)

export const truthy = <T>(v: T): boolean => !!v

export const identity = <T>(v: T): T => v

export const prop =
  <T extends {}>(key: keyof T) =>
  (value: T) =>
    value[key]
