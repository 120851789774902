import { sliderAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(sliderAnatomy.keys)

const baseStyle = definePartsStyle({
  filledTrack: {
    backgroundColor: "rgba(36, 47, 80, 0.12)",
  },
  thumb: {
    backgroundColor: "snow.blue-dark",
  },
})

export const sliderTheme = defineMultiStyleConfig({
  baseStyle,
})
