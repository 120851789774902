import { defineStyleConfig } from "@chakra-ui/react"

export const Heading = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    color: "snow.blue-dark",
  },
  // Two variants: outline and solid
  variants: {
    display1: {
      fontSize: 48,
    },
    display2: {
      fontSize: [32, null, 42, null, null],
    },
    h1: {
      fontSize: [24, null, 28, null, null],
      lineHeight: ["32px"],
    },
    h2: {
      fontSize: [20, null, 24, null, null],
      lineHeight: ["28px", null, "32px", null, null],
    },
    h3: {
      fontSize: [18, null, 20, null, null],
      lineHeight: ["24px", null, "28px", null, null],
    },
    h4: {
      fontSize: [16, null, 18, null, null],
      lineHeight: ["24px", null, "28px", null, null],
    },
    heroTitle: {
      fontSize: [16, null, 32, 44, null],
    },
    heroSubtitle: {
      fontSize: [14, null, 28, 38, null],
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: "h1",
  },
})
