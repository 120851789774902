import { Icon, IconProps } from "@chakra-ui/react";
const SortIcon = (props: IconProps) => <Icon {...props} data-sentry-element="Icon" data-sentry-component="SortIcon" data-sentry-source-file="Sort.tsx">
    <g clipPath="url(#clip0_4010_155292)" data-sentry-element="g" data-sentry-source-file="Sort.tsx">
      <path d="M9.18127 2.69087C9.08271 2.58708 8.91729 2.58708 8.81873 2.69087L5.40095 6.2895C5.2497 6.44876 5.36259 6.71167 5.58222 6.71167H7.75C7.88807 6.71167 8 6.8236 8 6.96167V13.8611C8 13.9992 8.11193 14.1111 8.25 14.1111H9.75C9.88807 14.1111 10 13.9992 10 13.8611V6.96167C10 6.8236 10.1119 6.71167 10.25 6.71167H12.4178C12.6374 6.71167 12.7503 6.44876 12.5991 6.2895L9.18127 2.69087ZM16.25 17.2883C16.1119 17.2883 16 17.1764 16 17.0383V10.1389C16 10.0008 15.8881 9.88889 15.75 9.88889H14.25C14.1119 9.88889 14 10.0008 14 10.1389V17.0383C14 17.1764 13.8881 17.2883 13.75 17.2883H11.5822C11.3626 17.2883 11.2497 17.5512 11.4009 17.7105L14.8187 21.3091C14.9173 21.4129 15.0827 21.4129 15.1813 21.3091L18.5991 17.7105C18.7503 17.5512 18.6374 17.2883 18.4178 17.2883H16.25Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Sort.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Sort.tsx">
      <clipPath id="clip0_4010_155292" data-sentry-element="clipPath" data-sentry-source-file="Sort.tsx">
        <rect width="24" height="24" fill="white" data-sentry-element="rect" data-sentry-source-file="Sort.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default SortIcon;