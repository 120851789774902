import { Icon, IconProps } from "@chakra-ui/react";
const CoinIcon = (props: IconProps) => <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="CoinIcon" data-sentry-source-file="Coin.tsx">
    <g clipPath="url(#clip0_4073_154687)" data-sentry-element="g" data-sentry-source-file="Coin.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Coin.tsx" />
      <path d="M12.3448 11.0222C10.3379 10.4978 9.69253 9.95556 9.69253 9.11111C9.69253 8.14222 10.5855 7.46667 12.0796 7.46667C13.1746 7.46667 13.7902 7.83252 14.0812 8.42945C14.3104 8.89938 14.7008 9.33333 15.2236 9.33333C15.7802 9.33333 16.2383 8.8617 16.0701 8.33122C15.7183 7.22146 14.8384 6.29817 13.4057 5.94667V5C13.4057 4.44772 12.958 4 12.4057 4H11.7534C11.2012 4 10.7534 4.44772 10.7534 5V5.92C9.03831 6.29333 7.65914 7.41333 7.65914 9.12889C7.65914 11.1822 9.34774 12.2044 11.8143 12.8C14.0246 13.3333 14.4666 14.1156 14.4666 14.9422C14.4666 15.5556 14.0334 16.5333 12.0796 16.5333C10.797 16.5333 10.0625 16.1278 9.70628 15.5215C9.44759 15.0813 9.05446 14.6667 8.54387 14.6667C7.97678 14.6667 7.50945 15.1535 7.69959 15.6878C8.16789 17.0037 9.37959 17.7761 10.7534 18.0711V19C10.7534 19.5523 11.2012 20 11.7534 20H12.4057C12.958 20 13.4057 19.5523 13.4057 19V18.0889C15.1297 17.76 16.5 16.7556 16.5 14.9333C16.5 12.4089 14.3517 11.5467 12.3448 11.0222Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Coin.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Coin.tsx">
      <clipPath id="clip0_4073_154687" data-sentry-element="clipPath" data-sentry-source-file="Coin.tsx">
        <rect width={24} height={24} fill="white" data-sentry-element="rect" data-sentry-source-file="Coin.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default CoinIcon;