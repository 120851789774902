import { Icon, IconProps } from "@chakra-ui/react";
const EditIcon = (props: IconProps) => <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="EditIcon" data-sentry-source-file="Edit.tsx">
    <g clipPath="url(#clip0_4397_160503)" data-sentry-element="g" data-sentry-source-file="Edit.tsx">
      <path d="M21.7312 2.26884C20.706 1.24372 19.044 1.24372 18.0188 2.26884L16.8617 3.42599L20.574 7.1383L21.7312 5.98116C22.7563 4.95603 22.7563 3.29397 21.7312 2.26884Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Edit.tsx" />
      <path d="M19.5133 8.19896L15.801 4.48665L7.40019 12.8875C6.78341 13.5043 6.33002 14.265 6.081 15.101L5.28122 17.7859C5.2026 18.0498 5.27494 18.3356 5.46967 18.5303C5.6644 18.725 5.95019 18.7974 6.21412 18.7188L8.89901 17.919C9.73498 17.67 10.4957 17.2166 11.1125 16.5998L19.5133 8.19896Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Edit.tsx" />
      <path d="M5.25 5.24999C3.59315 5.24999 2.25 6.59314 2.25 8.24999V18.75C2.25 20.4068 3.59315 21.75 5.25 21.75H15.75C17.4069 21.75 18.75 20.4068 18.75 18.75V13.5C18.75 13.0858 18.4142 12.75 18 12.75C17.5858 12.75 17.25 13.0858 17.25 13.5V18.75C17.25 19.5784 16.5784 20.25 15.75 20.25H5.25C4.42157 20.25 3.75 19.5784 3.75 18.75V8.24999C3.75 7.42156 4.42157 6.74999 5.25 6.74999H10.5C10.9142 6.74999 11.25 6.41421 11.25 5.99999C11.25 5.58578 10.9142 5.24999 10.5 5.24999H5.25Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Edit.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Edit.tsx">
      <clipPath id="clip0_4397_160503" data-sentry-element="clipPath" data-sentry-source-file="Edit.tsx">
        <rect width="24" height="24" fill="white" data-sentry-element="rect" data-sentry-source-file="Edit.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default EditIcon;