import { Icon, IconProps } from "@chakra-ui/react";
const FlagIcon = (props: IconProps) => <Icon width={80} height={80} viewBox="0 0 80 80" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="FlagIcon" data-sentry-source-file="Flag.tsx">
    <path d="M77.995 41.452c-.053.067-.106.12-.16.187-18.104 15.213-39.181-.933-53.981 9.707h-.014c-2.76.427-2.666 4.694-5.173 5.987-3.027-10.827-8.8-25.16-11.827-36-.293-1.04 1.44-3 1.147-4.04 6.787-9.187 16.027-9.573 25.587-8.8 10.493.867 23.15 3.8 34.546-6.41-.791 6.886-2.127 14.347-3.367 19.56a147.068 147.068 0 0 1-1.28 5.054c-.024.114-.042.23-.053.347-.022.428.15.844.467 1.133 5.32 4.627 8.522 7.008 13.202 9.342.426.213.84.413 1.253.586.707.307.533 2.28-.347 3.347Z" fill="#62ABEA" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="m3.683 14.596.184-.051a3.218 3.218 0 0 1 3.965 2.233l16.27 58.246a2.574 2.574 0 0 1-1.787 3.172l-1.423.398a2.574 2.574 0 0 1-3.172-1.787L1.45 18.56a3.218 3.218 0 0 1 2.233-3.965Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="m2.738 18.204 4.665 16.693 11.614 41.558-2.568.718L4.835 35.615.17 18.92l2.568-.717Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="M3.248 24.983c.708-.2 1.121-.934.926-1.643l-1.436-5.137-2.568.718 1.435 5.136c.2.708.934 1.122 1.643.926ZM16.447 77.173l2.568-.718L7.043 33.613a1.333 1.333 0 0 0-2.568.718l11.972 42.842ZM5.608 28.477a1.403 1.403 0 0 0-.626-.808l-.236-.1a1.018 1.018 0 0 0-.264-.052.848.848 0 0 0-.253-.011c-.089.009-.177.024-.264.046a1.4 1.4 0 0 0-.807.626 1.376 1.376 0 0 0 .51 1.824c.154.081.324.133.498.15a1.32 1.32 0 0 0 1.442-1.676Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="M20.206 80.031a3.914 3.914 0 0 1-3.758-2.858 1.333 1.333 0 1 1 2.568-.717c.185.66.869 1.045 1.529.861l1.425-.398c.66-.186 1.045-.87.86-1.53L6.555 17.137a1.89 1.89 0 0 0-2.325-1.308l-.182.051a1.89 1.89 0 0 0-1.31 2.324 1.333 1.333 0 1 1-2.567.717 4.558 4.558 0 0 1 3.158-5.609l.184-.051a4.56 4.56 0 0 1 5.61 3.158l16.277 58.253a3.907 3.907 0 0 1-2.711 4.815l-1.426.398a3.943 3.943 0 0 1-1.056.146Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="M18.888 57.659a1.332 1.332 0 0 1-1.284-.974L6.698 17.656c-.11-.395-.033-.82.21-1.15 8.269-11.21 19.929-10.046 31.206-8.919C48.696 8.648 58.691 9.645 65.988.914a2.11 2.11 0 0 1 2.53-.84c.332.145 1.401.764 1.163 2.684a183.318 183.318 0 0 1-4.715 24.433 59.85 59.85 0 0 0 13.801 9.483 1.96 1.96 0 0 1 1.089 1.396 4.456 4.456 0 0 1-.91 3.576l-.004.006c-8.205 9.811-19.374 8.697-30.187 7.617-11.018-1.1-21.427-2.143-28.794 7.848-.252.34-.65.542-1.073.542ZM9.444 17.576 19.48 53.489c8.126-9.014 19.003-7.928 29.54-6.874 10.579 1.058 20.575 2.056 27.873-6.672.218-.299.349-.653.377-1.022a63.652 63.652 0 0 1-14.102-9.775 2.611 2.611 0 0 1-.777-2.646 178.556 178.556 0 0 0 4.443-22.538c-8.079 8.368-18.697 7.305-28.984 6.28C27.017 9.16 16.787 8.136 9.444 17.576Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="m38.157 18.427-10.35 17.926a2.13 2.13 0 0 0 1.844 3.195h20.7a2.13 2.13 0 0 0 1.844-3.195l-10.35-17.926a2.13 2.13 0 0 0-3.688 0Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="M40.136 23.955h-.27c-.665 0-1.204.539-1.204 1.204v5.753c0 .665.539 1.203 1.204 1.203h.27c.664 0 1.203-.539 1.203-1.203v-5.753c0-.665-.538-1.204-1.203-1.204ZM40 36.678a1.339 1.339 0 1 0 0-2.677 1.339 1.339 0 0 0 0 2.677Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="M52.833 35.985a2.865 2.865 0 0 1-2.481 4.298h-20.7a2.866 2.866 0 0 1-2.481-1.433l.216-.124-.216.124a2.865 2.865 0 0 1 0-2.865l3.947-6.837a.736.736 0 0 1 1.005-.27l-.124.217.125-.216a.736.736 0 0 1 .27 1.005h-.001l-3.947 6.837a1.394 1.394 0 0 0 1.206 2.09l23.181-2.826Zm0 0-10.35-17.927m10.35 17.927-10.35-17.927m0 0a2.865 2.865 0 0 0-4.962 0l-3.193 5.528m8.156-5.527-8.156 5.527m0 0a.74.74 0 0 0 .27 1.006.736.736 0 0 0 1.006-.27l3.191-5.527-4.467 4.791Zm17.417 13.832c0-.245-.064-.485-.187-.697l.187.697Zm0 0c0 .244-.064.484-.187.696m.187-.696-.187.696m0 0a1.393 1.393 0 0 1-.51.51m.51-.51-.51.51m0 0a1.393 1.393 0 0 1-.696.187m.696-.187-.696.187m0 0h-20.7 20.7ZM38.796 18.795a1.393 1.393 0 0 1 2.413 0l10.35 17.926-12.764-17.926Z" fill="#344372" stroke="#344372" strokeWidth={0.5} data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="M33.051 27.27a.488.488 0 0 0 .665-.178l.256-.444a.485.485 0 1 0-.842-.486l-.256.444a.487.487 0 0 0 .177.664Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M32.774 27.751a1.043 1.043 0 0 1-.38-1.422l.255-.444a1.04 1.04 0 1 1 1.804 1.042l-.255.443a1.042 1.042 0 0 1-1.424.381Zm1.199-1.103a.485.485 0 1 0-.842-.486l-.256.444a.486.486 0 0 0 .842.486l.256-.444Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
    <path d="M40.135 32.602a1.692 1.692 0 0 0 1.69-1.69V25.16a1.692 1.692 0 0 0-1.69-1.69h-.27a1.692 1.692 0 0 0-1.69 1.69v5.753a1.692 1.692 0 0 0 1.69 1.69h.27Zm-.987-1.69V25.16a.718.718 0 0 1 .718-.717h.27a.719.719 0 0 1 .717.717v5.753a.718.718 0 0 1-.718.718h-.27a.718.718 0 0 1-.717-.718ZM40 37.165a1.824 1.824 0 1 0 0-3.648 1.824 1.824 0 0 0 0 3.648Zm0-2.677a.852.852 0 1 1 0 1.706.852.852 0 0 1 0-1.707Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Flag.tsx" />
  </Icon>;
export default FlagIcon;