import { Icon, IconProps } from "@chakra-ui/react";
const PredictedIcon = (props: IconProps) => <Icon width={28} height={34} viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="PredictedIcon" data-sentry-source-file="Predicted.tsx">
    <path d="M23.2476 7.31494L24.1858 6.59868C24.4448 6.40089 24.8152 6.45057 25.013 6.70963C25.2108 6.9687 25.1611 7.33905 24.902 7.53684L23.9639 8.2531C23.7048 8.45089 23.3344 8.40122 23.1366 8.14215C22.9389 7.88309 22.9885 7.51273 23.2476 7.31494Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Predicted.tsx" />
    <path d="M24.2967 10.9681C24.1678 10.8007 24.1383 10.5771 24.2192 10.382C24.3002 10.1868 24.4792 10.0498 24.6888 10.0228L25.8584 9.87229C26.1827 9.82974 26.48 10.0581 26.5226 10.3824C26.5651 10.7067 26.3367 11.0041 26.0125 11.0466L24.8428 11.1971C24.6329 11.2242 24.4245 11.1368 24.2967 10.9681Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Predicted.tsx" />
    <path d="M20.7173 6.27676C20.5882 6.10902 20.5588 5.88497 20.6403 5.6896L21.0936 4.60095C21.1755 4.40584 21.3552 4.26921 21.565 4.24252C21.7749 4.21583 21.9831 4.30313 22.1111 4.47155C22.2392 4.63997 22.2677 4.86391 22.1859 5.05902L21.7325 6.14766C21.6512 6.34265 21.4718 6.4793 21.2622 6.50595C21.0527 6.5326 20.8448 6.44519 20.7173 6.27676Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Predicted.tsx" />
    <path d="M4.79082 26.4199L3.91922 27.2158C3.67853 27.4356 3.30524 27.4187 3.08546 27.178C2.86567 26.9373 2.88261 26.564 3.1233 26.3442L3.9949 25.5483C4.23559 25.3285 4.60888 25.3455 4.82866 25.5862C5.04845 25.8268 5.03151 26.2001 4.79082 26.4199Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Predicted.tsx" />
    <path d="M3.42447 22.8732C3.56755 23.0286 3.61663 23.2487 3.55315 23.4502C3.48967 23.6517 3.32333 23.8039 3.11699 23.8493L1.9651 24.1019C1.64581 24.1728 1.32952 23.9714 1.25865 23.6521C1.18778 23.3329 1.38916 23.0166 1.70845 22.9457L2.86034 22.693C3.06709 22.6476 3.28235 22.7163 3.42447 22.8732Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Predicted.tsx" />
    <path d="M7.40158 27.2316C7.54494 27.3873 7.59391 27.6079 7.5299 27.8097L7.17393 28.934C7.10956 29.1355 6.94256 29.2874 6.73583 29.3324C6.52911 29.3774 6.31407 29.3088 6.17171 29.1523C6.02935 28.9957 5.98131 28.7752 6.04568 28.5736L6.40166 27.4494C6.46553 27.248 6.63218 27.0961 6.8386 27.0511C7.04503 27.0062 7.25974 27.075 7.40158 27.2316Z" fill="#344372" data-sentry-element="path" data-sentry-source-file="Predicted.tsx" />
    <path d="M12.7194 9.06999C13.2164 7.71434 13.5853 6.70836 13.8511 6.02921C13.9059 5.88923 13.9554 5.76544 14 5.65701C14.0446 5.76544 14.0941 5.88923 14.1489 6.02921C14.4147 6.70836 14.7836 7.71434 15.2806 9.06999C15.793 10.4678 16.4455 11.809 17.2795 12.8217C18.4486 14.2413 20.1665 15.2502 21.8343 15.9612C22.8143 16.3791 23.5363 16.687 24.0246 16.9109C24.0942 16.9428 24.1579 16.9725 24.2159 17C24.1579 17.0275 24.0942 17.0572 24.0246 17.0891C23.5363 17.313 22.8143 17.6209 21.8343 18.0387C20.1665 18.7498 18.4486 19.7587 17.2795 21.1783C16.4455 22.191 15.793 23.5322 15.2806 24.93C14.7836 26.2857 14.4147 27.2916 14.1489 27.9708C14.0941 28.1108 14.0446 28.2346 14 28.343C13.9554 28.2346 13.9059 28.1108 13.8511 27.9708C13.5853 27.2916 13.2164 26.2857 12.7194 24.93C12.207 23.5322 11.5545 22.191 10.7205 21.1783C9.55138 19.7587 7.83351 18.7498 6.16573 18.0387C5.18573 17.6209 4.46374 17.313 3.97542 17.0891C3.90576 17.0572 3.84209 17.0275 3.78412 17C3.84209 16.9725 3.90576 16.9428 3.97542 16.9109C4.46374 16.687 5.18573 16.3791 6.16573 15.9612C7.83351 15.2502 9.55138 14.2413 10.7205 12.8217C11.5545 11.809 12.207 10.4678 12.7194 9.06999ZM24.638 17.2226C24.6472 17.2294 24.6444 17.2285 24.6342 17.2199C24.6359 17.221 24.6371 17.2219 24.638 17.2226ZM24.6342 16.7801C24.6444 16.7714 24.6472 16.7706 24.638 16.7774C24.6371 16.7781 24.6359 16.779 24.6342 16.7801ZM3.36201 16.7774C3.35278 16.7706 3.3556 16.7715 3.36577 16.7801C3.36413 16.779 3.36288 16.7781 3.36201 16.7774ZM3.36576 17.2199C3.35559 17.2285 3.35278 17.2294 3.36201 17.2226C3.36288 17.2219 3.36413 17.221 3.36576 17.2199Z" fill="currentColor" stroke="#344372" strokeWidth="1.3" data-sentry-element="path" data-sentry-source-file="Predicted.tsx" />
  </Icon>;
export default PredictedIcon;