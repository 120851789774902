import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";
const CirclePlusIcon = (props: IconProps) => <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="CirclePlusIcon" data-sentry-source-file="CirclePlus.tsx">
    <g clipPath="url(#clip0_8571_18450)" data-sentry-element="g" data-sentry-source-file="CirclePlus.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9V11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15V12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="CirclePlus.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="CirclePlus.tsx">
      <clipPath id="clip0_8571_18450" data-sentry-element="clipPath" data-sentry-source-file="CirclePlus.tsx">
        <rect width="24" height="24" fill="white" data-sentry-element="rect" data-sentry-source-file="CirclePlus.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default CirclePlusIcon;