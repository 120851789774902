import { debounce } from "lodash"
import { UIEvent, useCallback } from "react"

const isScrollTarget = (target: EventTarget): target is HTMLElement =>
  ["scrollTop", "offsetHeight", "scrollHeight"].every((key) => key in target)

export const useScrollHandler = (callback: () => void, interval = 500) =>
  useCallback(
    debounce(({ target }: UIEvent<HTMLElement>) => {
      if (!isScrollTarget(target)) {
        console.warn("not a valid scroll target")
        return
      }

      const { scrollTop, offsetHeight, scrollHeight } = target
      const progress = (scrollTop + offsetHeight) / scrollHeight

      if (progress > 0.99) {
        callback()
      }
    }, interval),
    [callback, interval],
  )
