import { FeatureFlags, FilterEnum, InviteLinkFeatureFlags } from "../types"
import { FilterState } from "@/filters/state/reducer"
import { FilterCategoriesType, FiltersType } from "@/filters/types"

/**
 * Maps the values of an Enum to an object with boolean values.
 *
 * @template T - The type of the Enum values.
 * @param {Enum<T>} e - The Enum object to map.
 * @returns {Record<string, boolean>} An object mapping the Enum values to boolean values.
 */
export const featureFlagEnumMap = <T extends FilterEnum<string>>(
  e: T,
): Record<string, boolean> => {
  const flags: Record<string, boolean> = {}
  for (const key in e) {
    flags[e[key]] = featureFlag(e[key])
  }
  return flags
}

/**
 * Determines if a feature flag is enabled.
 *
 * @param {string} feature - The name of the feature flag to check.
 * @returns {boolean} Returns true if the feature flag is enabled, false otherwise.
 */
export const featureFlag = (feature: string): boolean => {
  if (!process.env.NEXT_PUBLIC_DISABLED_FEATURE_FLAGS) {
    // If the disabled feature flags environment variable is not set, all features are enabled.
    return true
  }

  // Split the disabled feature flags string by commas, and remove any spaces from the resulting array.
  const disabledFlags = process.env.NEXT_PUBLIC_DISABLED_FEATURE_FLAGS.replace(
    /\s/g,
    "",
  ).split(",")

  // Return true if the given feature is not in the list of disabled feature flags.
  return !disabledFlags.includes(feature)
}

/**
 * Filters out keys from a FilterState object based on feature flags.
 *
 * @param {FilterState} filter - The FilterState object to filter.
 * @returns {FilterState} Returns a new FilterState object with the filtered keys removed.
 */
export const handleFiltersType = (filter: FilterState): FilterState => {
  // Get all enabled filters through feature flags
  const featureFlagsFilters = featureFlagEnumMap(FiltersType)

  return Object.fromEntries(
    Object.entries(filter).filter(([key]) => featureFlagsFilters[key]),
  )
}

/**
 * A collection of active feature flags mapped from featureFlags enums
 *
 * @example
 * featureFlags[FilterCategoryFeatureFlags.ELIGIBILITY_RESTRICTIONS]
 *
 * @type {FeatureFlags}
 */
export const featureFlags: FeatureFlags = {
  ...featureFlagEnumMap(FilterCategoriesType),
  ...featureFlagEnumMap(FiltersType),
  ...featureFlagEnumMap(InviteLinkFeatureFlags),
}
