import { Flex } from "@chakra-ui/react";
import BetaIcon from "@/icons/Beta";
import LogoIcon from "@/icons/Logo";
import SnowdayIcon from "@/icons/Snowday";
interface Props {
  color: string;
  showName?: boolean;
}
export const Logo = ({
  color,
  showName = false
}: Props) => <Flex gap="1" direction="row" alignItems="center" color={color} data-sentry-element="Flex" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
    <LogoIcon data-sentry-element="LogoIcon" data-sentry-source-file="Logo.tsx" />
    <SnowdayIcon color="snow.blue-medium" display={{
    base: showName ? "inline-block" : "none",
    lg: "inline-block"
  }} mt="3px" data-sentry-element="SnowdayIcon" data-sentry-source-file="Logo.tsx" />
    <BetaIcon display={{
    base: showName ? "inline-block" : "none",
    lg: "inline-block"
  }} ml={1} data-sentry-element="BetaIcon" data-sentry-source-file="Logo.tsx" />
  </Flex>;