import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import { usePushQueryUpdates } from "@/utils/hooks/usePushQueryUpdates";
export const PAGE_NUMBER_KEY = "page";
export const PAGE_SIZE_KEY = "page-size";
const INITIAL_PAGE_NUMBER = 0;
const INITIAL_PAGE_SIZE = 100;
interface PaginationContextType {
  pageNumber: number;
  pageSize: number;
  resetPageNumber: () => void;
  onPageNumberChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
}
const PaginationContext = createContext<PaginationContextType | undefined>(undefined);
export const PaginationProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const {
    query
  } = router;
  const pushQueryUpdates = usePushQueryUpdates();
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
  useEffect(() => {
    deserializePagination();
  }, [query]);
  const resetPageNumber = () => {
    if (pageNumber === INITIAL_PAGE_NUMBER) {
      return;
    }
    handlePageNumberChange(INITIAL_PAGE_NUMBER);
  };
  const handlePageNumberChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
    serializePagination(newPageNumber, pageSize);
  };
  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    serializePagination(pageNumber, newPageSize);
  };
  const serializePagination = (newPageNumber: number, newPageSize: number) => {
    pushQueryUpdates({
      [PAGE_NUMBER_KEY]: newPageNumber !== INITIAL_PAGE_NUMBER ? JSON.stringify(newPageNumber + 1) : undefined,
      [PAGE_SIZE_KEY]: newPageSize !== INITIAL_PAGE_SIZE ? JSON.stringify(newPageSize) : undefined
    });
  };
  const deserializePagination = () => {
    const queryPageNumber = query[PAGE_NUMBER_KEY] !== undefined ? parseInt((query[PAGE_NUMBER_KEY] as string)) - 1 : undefined;
    const queryPageSize = query[PAGE_SIZE_KEY] !== undefined ? parseInt((query[PAGE_SIZE_KEY] as string)) : undefined;
    setPageNumber(queryPageNumber ?? INITIAL_PAGE_NUMBER);
    setPageSize(queryPageSize ?? INITIAL_PAGE_SIZE);
  };
  const value: PaginationContextType = {
    pageNumber,
    pageSize,
    resetPageNumber,
    onPageNumberChange: handlePageNumberChange,
    onPageSizeChange: handlePageSizeChange
  };
  return <PaginationContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="PaginationProvider" data-sentry-source-file="PaginationProvider.tsx">
      {children}
    </PaginationContext.Provider>;
};
export function usePagination() {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error("usePagination must be used within a PaginationContext");
  }
  return context;
}