import { Icon, IconProps } from "@chakra-ui/react";
const ChatIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="ChatIcon" data-sentry-source-file="Chat.tsx">
    <g clipPath="url(#clip0_5604_172601)" data-sentry-element="g" data-sentry-source-file="Chat.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9883 3.00003C10.4805 2.99853 8.99664 3.37718 7.67357 4.10103C6.3505 4.82488 5.23084 5.87063 4.41787 7.14179C3.6049 8.41296 3.12481 9.86861 3.02188 11.3745C2.91896 12.8803 3.19651 14.3879 3.82894 15.758C4.02833 16.2066 4.04976 16.7142 3.88887 17.178L3.04977 19.678C2.99205 19.8548 2.98437 20.0442 3.02759 20.2251C3.07081 20.406 3.16324 20.5714 3.29463 20.7029C3.42602 20.8344 3.59123 20.927 3.77194 20.9702C3.95265 21.0135 4.1418 21.0058 4.31842 20.948L6.81576 20.108C7.27905 19.947 7.78619 19.9684 8.23425 20.168C9.45554 20.7337 10.7881 21.0177 12.1337 20.9991C13.4792 20.9806 14.8034 20.6598 16.0087 20.0606C17.2139 19.4614 18.2694 18.599 19.0972 17.537C19.9251 16.475 20.5043 15.2405 20.7921 13.9245C21.0798 12.6085 21.0688 11.2447 20.7598 9.93358C20.4508 8.62245 19.8518 7.39748 19.0069 6.34902C18.1619 5.30055 17.0927 4.45534 15.8779 3.87571C14.6632 3.29608 13.3339 2.99683 11.9883 3.00003ZM12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25ZM17.25 12C17.25 12.6904 16.6904 13.25 16 13.25C15.3096 13.25 14.75 12.6904 14.75 12C14.75 11.3096 15.3096 10.75 16 10.75C16.6904 10.75 17.25 11.3096 17.25 12ZM8 13.25C8.69036 13.25 9.25 12.6904 9.25 12C9.25 11.3096 8.69036 10.75 8 10.75C7.30964 10.75 6.75 11.3096 6.75 12C6.75 12.6904 7.30964 13.25 8 13.25Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Chat.tsx"></path>
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Chat.tsx">
      <clipPath id="clip0_5604_172601" data-sentry-element="clipPath" data-sentry-source-file="Chat.tsx">
        <rect width="24" height="24" fill="#fff" data-sentry-element="rect" data-sentry-source-file="Chat.tsx"></rect>
      </clipPath>
    </defs>
  </Icon>;
export default ChatIcon;