import { Icon, IconProps } from "@chakra-ui/react";
const ShareIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="ShareIcon" data-sentry-source-file="Share.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="Share.tsx">
      <path d="m19.814 11.198-6.698-6.053a.554.554 0 0 0-.93.425v2.459H12a7.943 7.943 0 0 0-5.654 2.385A8.212 8.212 0 0 0 4 16.163v2.27c0 .127.042.251.12.352a.556.556 0 0 0 .937-.099l.176-.358A6.92 6.92 0 0 1 7.768 15.5a6.762 6.762 0 0 1 3.622-1.039h.796v3.216a.574.574 0 0 0 .329.519.55.55 0 0 0 .6-.095l6.697-6.053a.567.567 0 0 0 .188-.424.575.575 0 0 0-.188-.425h.002Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Share.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Share.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="Share.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Share.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default ShareIcon;