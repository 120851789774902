export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export const SortFieldNames = [
  "relevancy",
  "deadline",
  "deadlineStatus",
  "selectivity",
  "financialAccessibility",
  "distance",
] as const

export type SortField = (typeof SortFieldNames)[number]

export type SortOrdering = {
  field: SortField
  order: SortOrder
}
