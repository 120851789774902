import { defineStyleConfig } from "@chakra-ui/react"

export const Spinner = defineStyleConfig({
  // The styles all spinners have in common
  baseStyle: {
    color: "snow.blue",
    emptyColor: "snow.light-blue",
  },
  defaultProps: {
    size: "xl",
  },
})
