import { Icon, IconProps } from "@chakra-ui/react";
const QuestionMarkIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="QuestionMarkIcon" data-sentry-source-file="QuestionMark.tsx">
    <g clipPath="url(#a)" fill="currentColor" data-sentry-element="g" data-sentry-source-file="QuestionMark.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 2c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Z" data-sentry-element="path" data-sentry-source-file="QuestionMark.tsx" />
      <path d="M11.318 12.258c.564-1.024 1.648-1.628 2.278-2.535.666-.95.293-2.726-1.597-2.726-.816 0-1.358.41-1.706.912-.278.401-.781.649-1.23.459-.6-.254-.92-.941-.564-1.487A4.08 4.08 0 0 1 11.992 5c1.721 0 2.9.788 3.5 1.776.514.847.814 2.431.023 3.61-.88 1.305-1.721 1.702-2.176 2.542-.104.194-.173.35-.213.652-.072.548-.49.999-1.043.999h-.13c-.547 0-1.022-.44-.987-.987.027-.43.113-.9.352-1.334Zm2.146 5.268c0 .81-.66 1.474-1.465 1.474a1.474 1.474 0 0 1 0-2.947c.806 0 1.465.663 1.465 1.473Z" data-sentry-element="path" data-sentry-source-file="QuestionMark.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="QuestionMark.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="QuestionMark.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="QuestionMark.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default QuestionMarkIcon;