import { defineStyleConfig } from "@chakra-ui/react"

export const Text = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    color: "snow.blue-dark",
  },
  // Two variants: outline and solid
  variants: {
    block: {
      fontSize: [18, null, 20, null, 24],
    },
    body: {
      fontSize: [16, null, 18, null, null],
    },
    smallBody: {
      fontSize: [14, null, 16, null, null],
    },
    footer: {
      fontSize: 12,
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: "body",
  },
})
