import { Icon, IconProps } from "@chakra-ui/react";
const MenuIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="MenuIcon" data-sentry-source-file="Menu.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="Menu.tsx">
      <path fill="currentColor" d="M3 17a1 1 0 0 0 1 1h16a1 1 0 1 0 0-2H4a1 1 0 0 0-1 1Zm0-5a1 1 0 0 0 1 1h16a1 1 0 1 0 0-2H4a1 1 0 0 0-1 1Zm1-6a1 1 0 0 0 0 2h16a1 1 0 1 0 0-2H4Z" data-sentry-element="path" data-sentry-source-file="Menu.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Menu.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="Menu.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Menu.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default MenuIcon;