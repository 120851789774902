import { add, format, isValid } from "date-fns"

import { FilterState } from "../filters/state/reducer"
import { SortField, SortFieldNames } from "../sorting/types"
import { FiltersType } from "@/filters/types"
import { Provider } from "@/generated/graphql"

export const makeBold = (query: string, name: string): string => {
  const regex = new RegExp(`^${query}`, "gi")
  return name.replace(regex, `<span style="font-weight: 800">$&</span>`)
}
export const makePlacesBold = (
  substrings: { offset: number; length: number }[],
  name: string,
): string => {
  // If no substrings or name are specified, return the input string unmodified
  if (substrings.length === 0 || name === "") {
    return name
  }

  let currentIndex = 0
  let boldedName = ""

  for (const { offset, length } of substrings) {
    // Add the non-matching part of the input string to the output
    boldedName += name.slice(currentIndex, offset)

    // Add the matching part of the input string to the output with a span tag
    boldedName += `<span style="font-weight: 800">${name.slice(
      offset,
      offset + length,
    )}</span>`

    currentIndex = offset + length
  }

  // Add the remaining non-matching part of the input string to the output
  boldedName += name.slice(currentIndex)

  return boldedName
}

export const getVideoID = (string: string): string | null => {
  const match = string.match(/[^/]*\.com\/([^/]+)/)
  if (match?.[1]) {
    return match[1]
  }
  return null
}

/** RegEx to check if the link is external or not
 *  (https?://) matches "http://" or "https://".
 *  (.*.)? matches zero or one occurance of any character then a period (..)
 *  snow. matches a literal "snow."
 *  day/? matches a literal "day" and zero or one "/".
 *  . matches any sequence of characters.
 *
 */
export const isExternal = (href: string = ""): boolean =>
  href.match(/(?:https?:\/\/)?(.*\.)?snow\.day\/?.*/) == null

export const extractYoutubeID = (url: string): string | undefined => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\\&v=|\/live\/|\/embed\/|\/v\/|\/watch\?v=|\\&v=)([^#\\&\\?]*).*/
  const match = url.match(regExp)

  const youtubeId = match?.[2]?.trim()
  return youtubeId?.length === 11 ? youtubeId : undefined
}

export const parseIdentifier = (identifier: string | string[]): string =>
  typeof identifier === "string" ? identifier : identifier.join("")

export const normaliseURISort = (sort: unknown): SortField | null => {
  if (typeof sort === "string") {
    return SortFieldNames.find((n) => n === sort) ?? null
  }
  return null
}

export const normalizeURIArgument = (
  value: string | string[] | undefined,
): string[] =>
  value
    ? Array.isArray(value)
      ? value.map((v) => v.toLowerCase())
      : [value.toLowerCase()]
    : []

export const handleURIFilter = (value: string | undefined): FilterState => {
  if (!value) {
    return {}
  }
  try {
    const obj: unknown = JSON.parse(value)
    if (!verifyFilterType(obj)) {
      throw new Error("Invalid filter query provided")
    }
    return obj
  } catch (e) {
    return {}
  }
}

// Verify only these for the moment
const verifyFilterType = (obj: unknown): obj is FilterState => {
  return (
    (obj as FilterState)?.[FiltersType.TYPE] !== undefined ||
    (obj as FilterState)?.[FiltersType.FINANCIAL_ACCESSIBILITY] !== undefined ||
    (obj as FilterState)?.[FiltersType.SELECTIVE] !== undefined ||
    (obj as FilterState)?.[FiltersType.INTERESTS] !== undefined ||
    (obj as FilterState)?.[FiltersType.GRADES] !== undefined ||
    (obj as FilterState)?.[FiltersType.DEADLINE] !== undefined ||
    (obj as FilterState)?.[FiltersType.LOCATION] !== undefined ||
    (obj as FilterState)?.[FiltersType.AVAILABILITY] !== undefined ||
    (
      obj as FilterState & { [FiltersType.PROVIDERS]?: Provider[] | Provider }
    )?.[FiltersType.PROVIDERS] !== undefined
  )
}

export const titlecase = (value: string): string =>
  value
    .split(" ")
    .map(
      (element) =>
        `${element?.[0]?.toUpperCase()}${element
          ?.substring?.(1)
          ?.toLowerCase?.()}`,
    )
    .join(" ")

type Stringable = { toString: () => string }
export const stringify = <K extends keyof FilterState>(
  value: string | FilterState[K] | Stringable,
) => {
  return typeof value === "string" ? value : value!.toString()
}

export const getDateFromNow = (
  type: "years" | "months" | "weeks" | "days" | "hours" | "minutes" | "seconds",
  amount: number,
) => {
  return add(new Date(), { [type]: amount })
}

export const formatInputDate = (date: string | Date): string => {
  const dateObj = new Date(date)
  if (isValid(dateObj)) {
    return format(new Date(date), "yyyy-MM-dd")
  }
  return ""
}
