import { Icon, IconProps } from "@chakra-ui/react";
const CommunitySmallIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="CommunitySmallIcon" data-sentry-source-file="CommunitySmall.tsx">
    <g fill="currentColor" clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="CommunitySmall.tsx">
      <path d="M20.262 12.643a5.365 5.365 0 0 0-4.38-1.252 4.131 4.131 0 0 1-1.845 2.13 6.234 6.234 0 0 1 3.838 3.796A8.806 8.806 0 0 0 22 15.661a5.354 5.354 0 0 0-1.738-3.018ZM7.26 10.449c.203 0 .404-.021.602-.058a4.143 4.143 0 0 1 2.502-4.34A3.275 3.275 0 0 0 7.26 3.861a3.298 3.298 0 0 0-3.293 3.295A3.297 3.297 0 0 0 7.26 10.45ZM16.166 9.885c0 .17-.011.34-.032.507a3.298 3.298 0 0 0 3.898-3.236 3.3 3.3 0 0 0-3.296-3.296 3.273 3.273 0 0 0-3.102 2.192 4.142 4.142 0 0 1 2.532 3.833Z" data-sentry-element="path" data-sentry-source-file="CommunitySmall.tsx" />
      <path d="M11.03 6.736a3.278 3.278 0 0 0-2.241 3.883 3.28 3.28 0 0 0 3.21 2.559 3.277 3.277 0 0 0 3.207-2.546c.059-.25.087-.498.087-.747a3.276 3.276 0 0 0-2.321-3.148A3.23 3.23 0 0 0 12 6.59c-.336 0-.662.049-.97.146ZM6.126 17.318a6.276 6.276 0 0 1 3.84-3.794 4.136 4.136 0 0 1-1.852-2.133 5.406 5.406 0 0 0-.853-.069c-2.562 0-4.776 1.849-5.261 4.338a8.796 8.796 0 0 0 4.126 1.658Z" data-sentry-element="path" data-sentry-source-file="CommunitySmall.tsx" />
      <path d="M17.266 18.393a5.312 5.312 0 0 0-.112-.475A5.367 5.367 0 0 0 12 14.052a5.392 5.392 0 0 0-5.159 3.877 5.509 5.509 0 0 0-.108.463 8.779 8.779 0 0 0 5.266 1.733c1.92 0 3.734-.599 5.267-1.732Z" data-sentry-element="path" data-sentry-source-file="CommunitySmall.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="CommunitySmall.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="CommunitySmall.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="CommunitySmall.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default CommunitySmallIcon;