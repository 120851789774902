import { isEqual } from "lodash"
import { useRouter } from "next/router"
import { useMemo } from "react"

import { usePushQueryUpdate } from "./usePushQueryUpdate"
import { unwrap } from "../array"

export const useQuerySyncedValue = <T, TInitial = undefined>(
  key: string,
  serialize: (value: T | TInitial) => string | undefined,
  deserialize: (param: string | undefined) => T | undefined,
  initialValue: TInitial,
): [T | TInitial, (newValue: T | TInitial) => void] => {
  const pushQueryUpdate = usePushQueryUpdate()
  const { query: urlParams } = useRouter()

  const value = useMemo(() => {
    return deserialize(unwrap(urlParams[key])) ?? initialValue
  }, [urlParams, deserialize])

  const setValue = (newValue: T | TInitial) => {
    if (isEqual(newValue, initialValue)) {
      pushQueryUpdate(key, undefined)
    } else {
      pushQueryUpdate(key, serialize(newValue))
    }
  }

  return [value, setValue]
}
