import { Icon, IconProps } from "@chakra-ui/react";
const PlayIcon = (props: IconProps) => <Icon viewBox="0 0 20 24" width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="PlayIcon" data-sentry-source-file="Play.tsx">
    <g clipPath="url(#clip0_4010_155253)" data-sentry-element="g" data-sentry-source-file="Play.tsx">
      <path d="M4 20.231V3.83983C4 3.04698 4.87857 2.56945 5.5439 3.00067L18.6568 11.4998C19.2771 11.9018 19.2619 12.8146 18.6286 13.1957L5.51566 21.0878C4.84916 21.4889 4 21.0089 4 20.231Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Play.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Play.tsx">
      <clipPath id="clip0_4010_155253" data-sentry-element="clipPath" data-sentry-source-file="Play.tsx">
        <rect width="24" height="24" fill="white" data-sentry-element="rect" data-sentry-source-file="Play.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default PlayIcon;